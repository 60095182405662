// 驾驶舱接口
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Dashboard/`

// 列表
export const getTotalData = (params, config = {}) => {
    return request.get(apiPrefix + 'TotalData', { params: params, ...config })
}
 
// 订单数据分析
export const getOrderStatus = (params, config = {}) => {
    return request.get(apiPrefix + 'OrderStatus', { params: params, ...config })
}
 
// 微信定气数据分析
export const getWeChatStatus = (params, config = {}) => {
    return request.get(apiPrefix + 'WeChatStatus', { params: params, ...config })
}

 // 通讯数据分析
export const getCalllogsStatus = (params, config = {}) => {
    return request.get(apiPrefix + 'CalllogsStatus', { params: params, ...config })
}
 
 // 站点销售数据分析
 export const getStationSales = (params, config = {}) => {
    return request.get(apiPrefix + 'StationSales', { params: params, ...config })
}
 
 // 左侧导航数据查询
 export const getOrderCount = (params, config = {}) => {
    return request.get(apiPrefix + 'OrderCount', { params: params, ...config })
}