/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2024-01-23 11:09:49
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-01-23 14:14:17
 * @FilePath: \src\api\formDataConfig.js
 */
// 来电其他业务类型
import request from '@/utils/request' 
const apiJavaPrefix = `${process.env.VUE_APP_BASE_API}/FormDataConfig/`

// 查询
export const getQueryJson= (params, config = {}) => {
    return request.get(apiJavaPrefix + 'QueryJson', {params:params, ...config});
};
// 新增
export const postAddOrUpdate= (params, config = {}) => {
    return request.post(apiJavaPrefix + 'AddOrUpdate', params,config);
};