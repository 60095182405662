<!-- 气瓶规格弹窗 -->
<template> 
    <XkContainer>
    <div class="dialog-add flex-col" :style="{height:getHeight}">
    <div class="flex1">
        <el-table ref="mainTable"  highlight-current-row  border :data="tableData" 
             tooltip-effect="dark"  
             @selection-change="handleSelectionChange"
              
        height="100%" v-loading="tableLoading">
        <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column label="序号" width="80" type="index" align="center"></el-table-column>
            <el-table-column label="钢瓶规格"  prop="CylinderSpec" ></el-table-column>
            <el-table-column label="单价" width="180" prop="Price" ></el-table-column> 
          
        </el-table>
    </div> 
        <el-form class="mt-10">
            <el-form-item align="right">
                <el-button round @click="close"> 取消</el-button>
                <el-button type="primary" round @click="addInfo">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
    </XkContainer>
</template>
<script>
import { getCylinderPledgePriceList
} from '@/api/cylinderPledge' 
export default {
    name:'placeAnOrderAddCustomer',
    data(){
        return{
            
          tableLoading:false,// 显示列表加载状态
          tableData:[],
          dataForm:[],
          queryData:{
            pageIndex:1,
            PageSize:100000,
          }
        }
    },
    props:{
        getHeight:{
            type:String,
            default:'70vh'
        },
       
        
    },
    created(){ 
        this.getpageList();
       
    },
    methods:{ 
        async getpageList(){
            let res =await getCylinderPledgePriceList(this.queryData);
            if(res.Success){ 
            //   if (this.queryData.pageIndex == 1) {
                this.tableData = res.Data;
                // } else {
                // this.tableData = this.tableData.concat(res.Data.Data);
                // }
            }
        },
        addInfo(){
            this.$emit('addInfo',this.dataForm) 
        },
        close(){
            this.$emit('closeInfo')
        },
        handleSelectionChange(value){
            
             this.dataForm=value;  
             console.log(this.dataForm)
        },
        handleDel(){}
    }
}
</script>
<style lang="scss" scoped> 

</style>