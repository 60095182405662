<!--
@description: 通用下拉框组件
@prop  {url<String>}       接口URL
@prop  {urlParams<Object>} 接口URL的参数
@prop  {placeholder<string>}  placeholder
@prop  {label<string>}    label字段
@prop  {value<string>}    value字段
-->
<template>
  <el-cascader
    v-model="values"
    :options="options"
    :show-all-levels="false"
    @change="handleChange"
    :placeholder="placeholder"
    :props="{ checkStrictly: true,label:'Name',children:'Children',value:'Id' }"
    style="width:100%"
    clearable filterable></el-cascader>
</template>

<script>
import { getDivisionListCode } from '@/api/common'; 
export default {
  name:"DropDownStationList",
  data() {
    return {
      values: [],
      options: [],
      disabled:false, 
    };
  },
  props: {
    placeholder:String,
    urlParams: {
      type: Object,
      default: () => ({})
    },
    val:String
  },
  computed: {

  },
  watch:{
    val:{
      handler(n){
         
        
      },
      immediate:true,
      //deep:true,
    },
  },
  created() {
      this.getOptions();
  },
  methods: {
    async getOptions() {
        let params = {};
       //查询条件
        for (let i in this.urlParams) {
          if (this.urlParams[i]) {
            params[i] = this.urlParams[i];
          }
        }
       try {
        let res = await getDivisionListCode(params);
        if (res.code == 1) {          
          this.options = res.Data;
          if(this.val){
            this.options.some(item=>{
                if(item.Id == this.val){
                    this.values = [item.Id];
                    return true;
                }else{
                    item.Children.some(c =>{
                        if(c.Id == this.val){
                            this.values = [item.Id,c.Id];
                            return true;
                        }
                    })
                }
            })
          }else{
            this.values = [];
          }
        }
      } catch (error) {}
    },
    handleChange(value) {
      let val = this.$cloneDeep(value).pop();
      this.$emit("update:val", val);
    },
  },
};
</script>

<style>
</style>