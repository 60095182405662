<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-08-04 12:14:47
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-05-26 17:51:41
 * @FilePath: \呼叫中心\src\views\placeAnOrder\components\component\telephoneRecords.vue
-->
<!-- 电话记录 --> 
<template>
  <XkContainer id="telephoneRecords">
    <!-- 表格-->
    <div class="table-box">
      <el-table
        ref="mainTable"  highlight-current-row 
        border
        :data="tableData"
        height="100%"
        v-loading="tableLoading"
      >
        <el-table-column
        width="55"
          label="序号"
          prop="RowIndex"
          align="center"
        ></el-table-column>
        <el-table-column label="呼叫时间" prop="Calltime"></el-table-column>
        <el-table-column label="呼叫来源" prop="Callfrom"></el-table-column>
        <el-table-column label="呼叫目的地" prop="Callto"> </el-table-column>
        <el-table-column
          label="呼叫方向"
          prop="Calldirection"
        ></el-table-column>
        <el-table-column label="状态" prop="Callstatus"> 
          <template v-slot="scope">
        <template v-if="scope.row.StatusName!='已接'"> 
          <el-tag effect="dark" type="danger" round>{{scope.row.StatusName}}</el-tag> 
        </template>
        <template v-else> 
          <el-tag effect="dark" type="success" round>{{scope.row.StatusName}}</el-tag> 
        </template>
          
        </template>
        </el-table-column>
      </el-table>
    </div>
  </XkContainer>
</template>
<script>
import { getCallList } from "@/api/call";
import { mapGetters } from "vuex";
export default {
  name: "telephoneRecords",
  data() {
    return {
      stateBackArry: ["#F5222D", "#52C41A"],
      tableLoading: false,
      tableData: [],
    };
  },
  computed: {
    ...mapGetters({
      gettOrderInfoData: "gettOrderInfoData",
    }),
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        if(n.Id == o.Id)return
        this.getPageList();
      },
      deep: true,
      // immediate: true,
    },
  },
  created() {
    // this.getPageList();
  },
  methods: {
    async getPageList() { 
      if(Object.assign([], this.gettOrderInfoData.Phone).length !=11)return
   //   if (this.gettOrderInfoData.Phone.length != 11) return;
      let params = {
        Callfrom: this.gettOrderInfoData.Phone,
        pageIndex: 1,
        PageSize: 10,
      }; 
      let res = await getCallList(params);
      if (res) {
        this.tableData = res.Data.Data;
        this.total = res.Data.Count;
      }
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.table-box {
  height: calc(100vh - 280px);
}
</style>