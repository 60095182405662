<!--
 * @Description: 
 * @Author: wangjieqiong
 * @Date: 2022-06-14 09:28:26
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-05-06 14:22:49
 * @FilePath: \src\components\XkDateRangePick.vue
-->
<!-- 通用时间段选择 
<XkDateRangePick 
:startValue.sync=""
:endValue.sync="">
</XkDateRangePick>
-->
<template>
  <el-date-picker
    :clearable="clearable"
    v-model="queryDataTimeRange"
    :type="daterange"
    :value-format="valueFormat"
    :range-separator="placeHolder[2]"
    :start-placeholder="placeHolder[0]"
    :end-placeholder="placeHolder[1]"
    @change="change"
    :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
export default {
  name: 'XkDateRangePick',
  props: {
    startValue: String, //第1个值
    endValue: String, //第2个值
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    daterange: {
      type: String,
      default: 'daterange',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeHolder: {
      type: Array,
      default() {
        return ['开始日期', '结束日期', '至'];
      },
    },
    minMax: {
      type: Number,
      default: 0,
    },
  },
  created() {
    if (this.startValue) {
      this.queryDataTimeRange = [this.startValue, this.endValue];
    }
  },
  watch: {
    startValue(nval) {
      if (!nval) {
        this.queryDataTimeRange = [];
      }
    },
  },
  data() {
    return {
      selectDate: '',
      queryDataTimeRange: [],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '' && this.minMax != 0) {
            const one = this.minMax * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        },
      },
    };
  },
  methods: {
    change(val) {
      console.log('timerangechange:', val);
      this.$emit('update:startValue', val ? val[0] : '');
      this.$emit('update:endValue', val ? val[1] : '');
    },
  },
};
</script>

<style></style>
