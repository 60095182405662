/*
 * @Description: 上传文件
 * @Author: MasterGehong
 * @Date: 2022-09-16 10:19:26
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-02-26 17:11:57
 * @FilePath: \src\api\oss.js
 */ 

import request from '@/utils/request' 
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Oss/`


// 上传文件
export const postUploadImage = (params, config = {}) => {
    return request.post(apiPrefix + 'UploadImage', params,config )
} 