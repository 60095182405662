// 来电其他业务类型
import request from '@/utils/request'
const apiJavaPrefix = `/javaApi/OtherOrders/`;

// 新增其他业务
export const OtherOrdersAdd = (params, config = {}) => {
    return request.post(apiJavaPrefix + 'add', params, config);
};
// 修改其他业务
export const OtherOrdersUpdate = (params, config = {}) => {
    return request.post(apiJavaPrefix + 'update', params, config);
};
// 删除其他业务
export const OtherOrdersDelete = (params, config = {}) => {
    return request.post(apiJavaPrefix + 'delete', params, config);
};
// 处理回复其他类型单
export const OtherOrdersReturnMsg = (params, config = {}) => {
    return request.post(apiJavaPrefix + 'returnMsg', params, config);
};
// 其他业务详情
export const OtherOrdersDetail = (params, config = {}) => {
    return request.get(apiJavaPrefix + 'getDetail', { params: params, ...config });
};
// 其他业务列表
export const OtherOrdersGetList = (params, config = {}) => {
    return request.get(apiJavaPrefix + 'getList', { params: params, ...config });
};
// 最近3次其他类型单列表
export const OtherOrdersThreeList = (params, config = {}) => {
    return request.get(apiJavaPrefix + 'getThreeList', { params: params, ...config });
};