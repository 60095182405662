/**
 * 授权管理接口
 */
import request from '@/utils/request'

const apiPrefix = `${process.env.VUE_APP_BASE_API}/Auth/`
 console.log('apiPrefix',apiPrefix)
// 登录操作
export const getLogin = (params, config = {}) => {
    return request.post(apiPrefix + 'Login', params, config)
}
// 心跳30秒一次 超过35秒算离线
export const getHeartBeat = (params, config = {}) => {
    return request.get(apiPrefix + 'HeartBeat',{ params:params, ...config})
}
// 验证码
export const getVerifyCode = (params, config = {}) => {
    return request.get(apiPrefix + 'VerifyCode',{ params:params, ...config})
}
// 登出操作
export const getLogout = (params, config = {}) => {
    return request.get(apiPrefix + 'Logout',{ params:params, ...config})
}

// 工位号
export const callStation = (params, config = {}) => {
    return request.get('./js/callStation.json',{ params:params, ...config})
}

// 系统名称
export const titleText = (params, config = {}) => {
    return request.get('./js/titleText.json',{ params:params, ...config})
}
// 获取微信信息
export const postWxUserInfo = (params, config = {}) => {
    return request.post(apiPrefix+'GetWxInfo',params,  config)
}
// 微信绑定用户信息
export const getWxBindUserSingle = (params, config = {}) => {
    return request.post(apiPrefix+'getWxBindUserSingle',params,  config)
}
// 微信登录
export const postWxLogin = (params, config = {}) => {
    return request.post(apiPrefix+'WxLogin',params,  config)
}
 
// 超级管理员登录
export const postWxSupperLogin = (params, config = {}) => {
    return request.post(apiPrefix+'WxSupperLogin',params,  config)
}
// 超级管理员用户列表
export const postSupperWxBindUserList = (params, config = {}) => {
    return request.post(apiPrefix+'GetSupperWxBindUserList',params,  config)
}
// 登录页面修改密码
export const postUpdatePwd = (params, config = {}) => {
    return request.post(apiPrefix+'UpdatePwd2',params,  config)
}
