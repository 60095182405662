<!--
@description: 通用下拉框组件
@prop  {url<String>}       接口URL
@prop  {urlParams<Object>} 接口URL的参数
@prop  {placeholder<string>}  placeholder
@prop  {label<string>}    label字段
@prop  {value<string>}    value字段
-->
<template>
  <el-select v-model="values" :multiple="multiple" :placeholder="placeholder" @change="handleChange" filterable clearable :disabled="disabled" style="width:100%" :value-key="value">
    <el-option v-for="item in options" :key="item[value]" :label="item[lable]" :value="item"> </el-option>
  </el-select>
</template>

<script>
import common from '@/api/common';
export default {
  name: 'XkDropDown',
  data() {
    return {
      values: '',
      options: [],
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    multiple: {
      type: Boolean,
    },
    url: {
      type: String,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    lable: {
      type: String,
      default: 'Name',
    },
    value: {
      type: String,
      default: 'Id',
    },
    val: {
      type: String | Number | Array,
    },
    paramsWatch: {
      type: String | Number | Object,
    },
    getDataBoolen: {
      type: Boolean,
      default: true,
    },
    dataTarget: {
      type: Array,
      default() {
        return ['Data'];
      },
    },
  },
  computed: {},
  watch: {
    val: {
      handler(nVal) {
        if (nVal == '') this.values = '';
        this.getOptions();
      },
      deep: true,
    },
    paramsWatch: {
      handler(newVal, oldVal) {
        this.$emit('update:val', '');
        this.$emit('handleChange', '');
        this.getOptions();
      },
      deep: true,
    },
    urlParams: {
      handler(n, o) {
        this.getOptions();
      },
      deep: true,
    },
  },
  created() {
    this.getOptions();
  },
  methods: {
    getOptionsValue() {
      // this.options.forEach((item) => {
      //   if (item.Id == this.val) {
      //     this.values = item;
      //   }
      // });
      let offset = false;
      let itemData = {};
      this.options.forEach((item) => {
        if (item[this.value] == this.val) {
          itemData = item;
          offset = true;
        }
      });
      if (offset) {
        this.values = itemData;
      } else {
        this.$emit('update:val', '');
      }
    },
    async getOptions() {
      if (!this.getDataBoolen) return;
      let params = {};
      //查询条件
      for (let i in this.urlParams) {
        if (this.urlParams[i]) {
          params[i] = this.urlParams[i];
        }
      }
      try {
        // 下单页面送气工 站点没值不请求
        console.log(params);
        if (Object.keys(params).length == 0 && this.url == 'getStationOperList') {
          this.options = [];
          this.values = '';
          this.$emit('update:val', '');
          return;
        }
        let res = await common[this.url](params);
        if (res.Success) {
          if (
            this.url == 'gePstList' ||
            this.url == 'getJavaUserSelect' ||
            this.url == 'getCylinderRecycleUserListByStation' ||
            this.url == 'getSeletCylinderPledgePriceList' ||
            this.url == 'getStationOperList'
          ) {
            this.options = res.Data;
          } else {
            this.options = res.Data.Data;
          }
          if (this.val) {
            this.getOptionsValue();
          }
        }
      } catch (error) {}
    },
    handleChange(value) {
      this.$emit('update:val', this.values[this.value]);
      this.$emit('handleChange', value);
    },
  },
};
</script>

<style></style>
