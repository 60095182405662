/*
一、公共组件
二、调用方法
1.main.js
    > import publicMethod from "@/util/publicMethod.js"; //引入
    > Vue.prototype.$publicMethod = publicMethod;    //直接定义在vue的原型上面
2.调用
    > this.publicMethod.getCompanyBar();
*/   
import "@/plugins/echarts"; 
import * as echarts from 'echarts/core'; 
 
export default { 
    /*
      echarts 柱状图 
      id:容器id
      dataX：x轴数据
      dataY：Y轴数据
    */ 
    getJscPie(id,data,getColor){  
      let myChart
      if (myChart != null && myChart != "" && myChart != undefined) {
         myChart.dispose();//销毁
       }    
       myChart = echarts.init(document.getElementById(id)); 
       window.addEventListener('resize', () => {
        myChart.resize()
        })
       myChart.setOption({
        color:getColor,
        title: {
          show:false, 
        },
        grid: {
          left: "10%",
          right: "5%",
          bottom: "5%",
          top: "5%",
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:false,
          orient: 'vertical',
          left: 'left'
        },
        series: [
          { 
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            data: data,
            label:{
              show:false,
            },
            labelLine:{
              show:false,
            },
            emphasis: { 
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
       })
    },
    getAnalysisPie(id, data, getColor) {
      let myChart;
      if (myChart != null && myChart != '' && myChart != undefined) {
        myChart.dispose(); //销毁
      }
      myChart = echarts.init(document.getElementById(id));
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      myChart.setOption({
        // color: getColor,
        title: {
          show: false,
        },
        grid: {
          left: '10%',
          right: '5%',
          bottom: '5%',
          top: '10%',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: '60%',
            center: ['50%', '28%'],
            selectedMode: 'single',
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
      });
    },
    getJscLine(id,dataX,dataYa,dataYb){ 
      let myChart 
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();//销毁
       }   
       myChart = echarts.init(document.getElementById(id)); 
       window.addEventListener('resize', () => {
        myChart.resize()
        })
       myChart.setOption({
        color :['#228FFE', '#E77F53'],
        title: {
          show:false, 
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "10%",
          top: "20%",
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:true,
          orient: 'horizontal',
          right: '10%',
          top:'2%',
          textStyle:{
            color:'#fff',
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine:{
            lineStyle:{
              width:2,
              color:'rgba(34, 148, 236, 0.2)',
            }
          },
          axisLabel:{
            color :'#fff'
          },
          data: dataX,

        },
        yAxis: {
          name :'数量',
          type: 'value',
          axisLabel:{
            color :'#fff'
          },
          nameTextStyle:{
            color:'#fff'
          },
          splitLine:{
            lineStyle:{
              color:'rgba(34, 148, 236, 0.2)',
            }
          }

        },
        series: [
          {
            name: '已接',
            type: 'line', 
            data: dataYa, 
          },
          {
            name: '未接',
            type: 'line', 
            data: dataYb,
             
          }
        ]
       })
    },
    getJscLineBar(id,datax,cash,wechat,apipay,debt,total){ 
      let myChart 
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();//销毁s
       }    
       myChart = echarts.init(document.getElementById(id));
       window.addEventListener('resize', () => {
        myChart.resize()
        })
       myChart.setOption({
        color :['#00CCCD', '#EF8353','#007FFE','#A682E6','#FADE82'],
        title: {
          show:false, 
        },
        grid: {
          left: "4%",
          right: "3%",
          bottom: "15%",
          top: "25%",
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:true,
          orient: 'horizontal',
          left: '2%',
          top:'2%',
          textStyle:{
            color:'#fff',
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          boundaryGap :true,
          axisLine:{
            lineStyle:{
              width:2,
              color:'rgba(34, 148, 236, 0.2)',
            }
          },
          axisLabel:{
            color :'#fff'
          },
          data:datax,

        },
        yAxis: {
          
          name :'金额(元)',
          type: 'value',
          axisLabel:{
            color :'#fff'
          },
          nameTextStyle:{
            color:'#fff'
          },
          splitLine:{
            lineStyle:{
              color:'rgba(34, 148, 236, 0.2)',
            }
          }

        },
        series: [
          {
            name: '现金',
            type: 'bar',
            barWidth:'5%', 
            barMaxWidth:'5%', 
            data: cash,  
          },{
            name: '微信',
            type: 'bar',
            barWidth:'5%', 
            barMaxWidth:'5%',   
            data: wechat,  
          },
         {
            name: '支付宝',
            type: 'bar',
            barWidth:'5%', 
            barMaxWidth:'5%',  
            data: apipay,  
          },{
            name: '欠款',
            type: 'bar',
            barWidth:'5%', 
            barMaxWidth:'5%', 
            data: debt,  
          },
          {
            name: '销售总额',
            type: 'line', 
            data: total,
             
          }
        ]
       })
    }
  

  
     

} 