<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-08-04 12:14:47
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2024-10-24 14:52:46
 * @FilePath: \src\views\placeAnOrder\index.vue
-->
<!-- 下单管理 -->
<template>
  <XkContainer :class="{ 'my-elemente': myVariable }" :style="{ background: myBack }">
    <el-row style="height:100%" :gutter="20">
      <el-col :span="10" class="flex-col">
        <PlaceAnOrderAdd :getPhone="getPhone"></PlaceAnOrderAdd>
      </el-col>
      <el-col :span="14" class="flex-col">
        <PlaceAnOrderInfo :getPhone="getPhone" @showOrder="showOrder"></PlaceAnOrderInfo>
      </el-col>
    </el-row>
  </XkContainer>
</template>

<script>
import PlaceAnOrderAdd from './components/placeAnOrderAdd.vue';
import PlaceAnOrderInfo from './components/placeAnOrderInfo.vue';
import { getConfigList } from '@/api/sysConfig';
export default {
  name: 'placeAnOrder',
  data() {
    return {
      getPhone: '',
      myVariable: false,
      myBack: '#93c695',
    };
  },
  props: {
    orderPhone: {
      type: String,
      default: '',
    },
  },
  components: {
    PlaceAnOrderAdd,
    PlaceAnOrderInfo,
  },
  created() {
    this.getPhone = this.orderPhone;
    this.geStyleCss();
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleUnload);
  },
  methods: {
    async geStyleCss() {
      let res = await getConfigList();
      if (res.Success) {
        let resData = res.Data.filter((data) => data.Key == 'WebOrderMode');
        this.myBack = resData[0].Value == 1 ? '#93c695' : '#f0f5fb';
        this.myVariable = resData[0].Value == 1 ? true : false;
      }
    },
    showOrder() {
      this.$emit('showOrder', false);
    },
    async handleUnload(event) {
      // 执行需要的操作
    },
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  height: 100%;
}
</style>
