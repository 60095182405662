
export function getToken(key) {
  return window.sessionStorage.getItem(key)
}

export function setToken(key,val) {
  return window.sessionStorage.setItem(key, val)
}

export function removeToken(key) {
  return window.sessionStorage.removeItem(key)
}
