<template>
    <div class="amap-page-container">
        <template v-if="typeMap != 'view'">
            <el-button class="get-up-btn" type="primary" @click="getUpMap">确认</el-button>
          <el-amap-search-box ref="searchbox" class="search-box" :search-option="searchOption" :on-search-result="onSearchResult"> </el-amap-search-box>     
        </template>
        
      <el-amap :vid="'amap-vue'"   :amap-manager="amapManager" :plugin="plugin" :center="center" :events="events" class="amap-demo">  
       
        <!-- 标记 -->
        <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
        <el-amap-text v-for="(text,index) in texts" :text="text.text" :position="text.position" :offset="text.offset" :key="'1'+index"></el-amap-text>
      </el-amap>
    </div>
</template>

<script>
import {AMapManager} from 'vue-amap'
let amapManager = new AMapManager()
export default {
  props: {
    address:{
        type:String,
        default:''
    },
      longitude:{
          type: Number,
          default(){
            return 121.601333
          }
      },
      latitude:{
          type: Number,
          default(){
             return 31.174149
          }
      },
      typeMap:{ 
          type:String,
          default: 'add'
      },
      valMapNameAdress:{
          type:String,
          default: ''
      }
  },
 
  watch:{
    address:{
        handler(n,o){  
            this.changeLocation(this.address) 
        },
        deep:true,
        immediate:true,
    
    },
        mapLng:{
           handler:function (newVal) {         
              if(newVal!==null && newVal!==undefined && newVal!==""){
                    let that =this
                    this.markers = [];
                    this.texts = []
                    let lng = this.mapLng
                    let lat = this.mapLat
                    this.lng = lng
                    this.lat = lat
                    this.center = [lng, lat]
                    this.markers.push([lng, lat])
                    let geocoder = new AMap.Geocoder({
                        radius: 1000,
                        extensions: 'all'
                    })  
                    geocoder.getAddress([lng, lat], function (status, result) { 
                        if (status === 'complete' && result.info === 'OK') { 
                            if (result && result.regeocode) {
                                that.texts.push({
                                    position: [lng, lat],
                                    text:result.regeocode.formattedAddress,
                                    offset: [10, -50],
                                })
                                // that.$emit("getMap",that.texts)
                                that.$nextTick()
                            }
                        }
                    })
                    }
              
           },
           immediate:true,
        }, 
         valMapNameAdress:{ 
           handler: function (newVal) {   
                this.$nextTick(()=>{   //此处使用这个可以等节点渲染后再获取节点 
                    // 将关键字赋值到输入框内
                    this.$refs.searchbox._data.keyword = newVal
                    // 调用搜索实例
                    setTimeout(() => {
                        this.$refs.searchbox.search()
                    }, 500); 
                })              
           },
           immediate:true,
        },
    },
  data() {
        let self = this
    return {
        mapLng : this.longitude,
        mapLat : this.latitude,
        center: [121.59996, 31.197646],
        center: [],
        plugin: [],
        // markers: [[121.601333, 31.174149]],
        markers: [],
        searchOption: {
          city: '',
          citylimit: false
        },
        mapInfo:{},
        texts: [{
            // position: [121.601333, 31.174149],
            position: [],
            text:"初始化地点",
            // offset: [10, -50],
            offset: [0, 0],
        }],
        amapManager,
        lng: 0,
        lat: 0,
        events:{
            click(e) {
               
                self.markers = [];
                self.texts = []
                let {lng, lat} = e.lnglat
                self.lng = lng
                self.lat = lat
                self.center = [lng, lat]
                self.markers.push([lng, lat])
                let geocoder = new AMap.Geocoder({
                    radius: 1000,
                    extensions: 'all'
                })
                geocoder.getAddress([lng, lat], function (status, result) { 
                    if (status === 'complete' && result.info === 'OK') {
                        if (result && result.regeocode) {
                            self.texts.push({
                                position: [lng, lat],
                                text:result.regeocode.formattedAddress,
                                // offset: [10, -50],
                                offset: [0, 0],
                            })  
                            // self.$emit("getMap",self.texts)
                            self.$nextTick()
                            let oInput = document.getElementsByClassName('search-box')[0];
                            let aInput = oInput.getElementsByTagName('input')[0];
                            aInput.value=result.regeocode.formattedAddress 
                        }
                        
                    }
                })
            }
        }
    }
  },
  mounted(){
    let oInput = document.getElementsByClassName('search-box')[0];
    let aInput = oInput.getElementsByTagName('input')[0];
    this.$nextTick(()=>{
        setTimeout(() => {
            aInput.value=this.address  
        }, 2000); 
    })
    
  },
  methods:{
      getSeachMap(){
            console.log( this.$refs.searchbox) 
      },
      getUpMap(){
            this.$emit("getMap",this.texts)
      },
        //搜索
    onSearchResult(pois) {
        console.log(pois)      
        let that = this
        this.markers = []
        this.texts = []
        //console.log(pois,'pois');
        let lng = pois[0].lng
        let lat = pois[0].lat
        this.lng=pois[0].lng
        this.lat=pois[0].lat
        this.center = [lng, lat];
        this.markers.push([lng, lat])
        let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
        })
        geocoder.getAddress([lng, lat], function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
                if (result && result.regeocode) {
                    that.texts.push({
                        position: [lng, lat],
                        text:result.regeocode.formattedAddress,
                        // offset: [10, -50],
                        offset: [0, 0],
                    })
                    // that.$emit("getMap",that.texts)
                    that.$nextTick()
                }
            }
        })
      },
      //根据地址获取坐标
      changeLocation(data){
        let _this = this;
        _this.markers = [];
        _this.texts = []
        let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all"
        });  
        geocoder.getLocation(data,function(status, result){
            if (status === 'complete' && result.info === 'OK') {
                if (result && result.geocodes) {
                    let lng = result.geocodes[0].location.lng
                    let lat = result.geocodes[0].location.lat 
                    _this.center = [lng, lat]
                    _this.markers.push([lng, lat])
                    _this.texts.push({
                        position: [lng, lat],
                        text:result.geocodes[0].formattedAddress,
                        // offset: [10, -50],
                        offset: [0, 0],
                    })
                   
                    //_this.$emit("getMap",_this.texts)
                }
            }
        });
    } 
  }
}
</script>

<style>
.el-vue-amap-container.amap-demo{
    margin-top: 0;
width: 100%;
height: 100vh;
}
.el-vue-search-box-container.search-box {
position: absolute;
top: 20px;
left: 20px;
}
.get-up-btn{
    position: absolute;
top: 20px;
left: 390px;
z-index: 999;
height: 45px; 
    box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
    border-radius: 2px 3px 3px 2px;
}
.amap-page-container {
position: relative;
}
</style>