<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2023-08-15 16:33:54
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-08-15 17:09:06
 * @FilePath: \呼叫中心\src\compPages\XkReminder\index.vue
-->
<template>
  <div class="el-dialog-main">
    <el-form class="add-form-box add-form-style">
      <el-form-item label="催单原因">
        <el-input maxLength="100" border type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请填写原因" v-model="reminderForm.Reason"> </el-input>
      </el-form-item>
    </el-form>
    <div class="form-btn">
      <el-button type="primary" round @click="submitReminder">保存</el-button>
      <el-button round @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
import { OrderRemind } from '@/api/order';
export default {
  data() {
    return {
      showReminder: false,
      reminderForm: {
        Reason: '',
        Id: '',
      },
      overFinishRules: {
        Reason: [{ required: true, message: '催单原因', trigger: 'blur' }],
      },
    };
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  created() {
    this.reminderForm.Id = this.id;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submitReminder() {
      let res = await OrderRemind(this.reminderForm);
      if (res.Success) {
        this.showReminder = false;
        this.$message({
          type: 'success',
          message: '催单成功',
        });
        this.$emit('close');
      }
    },
  },
};
</script>

<style></style>
