import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Recycle/`
// 列表
export const PriceList = (params, config = {}) => {
    return request.get(apiPrefix + 'PriceList', { params: params, ...config})
}
// 新增修改价格
export const PriceAddOrUpdate = (params, config = {}) => {
    return request.post(apiPrefix + 'PriceAddOrUpdate ', params,config)
}   
// 删除价格
export const PriceDelete = (params, config = {}) => {
    return request.post(apiPrefix + 'PriceDelete', params,config)
}   
// 获取价格
export const GetSinglePrice = (params, config = {}) => {
    return request.get(apiPrefix + 'GetSinglePrice ',{ params: params, ...config})
}  
// 列表
export const RecycleList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config})
}
// 新增修改
export const AddOrUpdate = (params, config = {}) => {
    return request.post(apiPrefix + 'AddOrUpdate', params,config)
}
// 根据id获取回收记录
export const GetSingle = (params, config = {}) => {
    return request.get(apiPrefix + 'GetSingle', { params: params, ...config})
}
// 删除
export const RecycleDel = (params, config = {}) => {
    return request.post(apiPrefix + 'Del', params,config)
}
// 导出回收记录
export const ExportRecycle = (params, config = {}) => {
    return request.get(apiPrefix + 'ExportRecycle', { params: params, ...config})
}
// 指派
export const putAssign = (params, config = {}) => {
    return request.put(apiPrefix + 'Assign',  params,config)
}
// 撤销
export const putQuash = (params, config = {}) => {
    return request.put(apiPrefix + 'Quash',  params,config)
}