<!-- 下单管理 -->
<template>
  <el-tabs :class="{ 'my-elemente': myVariable }" v-model="customerSubscription" @tab-click="handleClick">
    <el-tab-pane label="客户订气" name="customerSubscription">
      <CustomerSubscription class="black-box" @showOrder="showOrder"></CustomerSubscription>
    </el-tab-pane>
    <el-tab-pane v-if="false" label="客户押瓶" name="customerSubscription1">
      <CustomerPledge class="black-box" @showOrder="showOrder"></CustomerPledge>
    </el-tab-pane>
    <el-tab-pane label="用气报修" name="customerSubscription2">
      <RepairWithGas class="black-box" @showOrder="showOrder"></RepairWithGas>
    </el-tab-pane>
    <el-tab-pane label="回收瓶订单" name="customerSubscription8">
      <RecycledBottle></RecycledBottle>
    </el-tab-pane>
    <el-tab-pane label="用户投诉" name="customerSubscription7">
      <CustomerComplaint class="black-box" @showOrder="showOrder"></CustomerComplaint>
    </el-tab-pane>
    <!-- <el-tab-pane label="其他" name="customerSubscription9">
      <Planother class="black-box" @showOrder="showOrder"></Planother>
    </el-tab-pane> -->
    <el-tab-pane v-if="false" label="回收瓶管理" name="customerSubscription3">
      <RecyclingBottle class="black-box" @showOrder="showOrder"></RecyclingBottle>
    </el-tab-pane>
    <el-tab-pane v-if="false" label="投诉登记" name="customerSubscription4">
      <ComplaintRegistration class="black-box" @showOrder="showOrder"></ComplaintRegistration>
    </el-tab-pane>
    <el-tab-pane label="订单记录" name="customerSubscription5">
      <OrderRecord class="black-box"></OrderRecord>
    </el-tab-pane>
    <el-tab-pane label="电话记录" name="customerSubscription6">
      <TelephoneRecords class="black-box"></TelephoneRecords>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import CustomerSubscription from './component/customerSubscription.vue'; //客户订气
import CustomerPledge from '@/views/retreatPressureGasCylinder/components/userManageAdd.vue'; //客户押瓶 -押瓶组件
import RepairWithGas from './component/repairWithGas'; //用气报修
//import RecyclingBottle from './component/recyclingBottle'; //回收瓶管理
import RecyclingBottle from '@/views/recycledBottle/components/userManageAdd.vue'; //回收瓶管理
import ComplaintRegistration from './component/complaintRegistration'; //投诉登记
import OrderRecord from './component/orderRecord.vue'; //订单记录
import TelephoneRecords from './component/telephoneRecords.vue'; //电话记录
import CustomerComplaint from './component/CustomerComplaint.vue'; //用户投诉表单
import Planother from './component/Planother'; //用户投诉表单

import RecycledBottle from '@/views/recycledBottle/components/userManageAdd';
import { getConfigList } from '@/api/sysConfig';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'placeAnOrderInfo',
  data() {
    return {
      upData: {},
      queryData: {},
      customerSubscription: 'customerSubscription',
      placeAnOrderForm: {},
      tagShow: false,
      myVariable: true,
    };
  },
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        let arr = Object.keys(n);
        if (arr.length != 0) {
          this.tagShow = true;
        } else {
          this.tagShow = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  components: {
    CustomerSubscription, //客户订气
    CustomerPledge, //客户押瓶
    RecyclingBottle, //回收瓶管理
    RepairWithGas, //用气报修
    ComplaintRegistration, //投诉登记
    OrderRecord, //订单记录
    TelephoneRecords, //电话记录
    CustomerComplaint, //用户投诉
    RecycledBottle,
    Planother, //其他
  },
  created() {
    this.geStyleCss();
  },
  mounted() {},
  methods: {
    async geStyleCss() {
      let res = await getConfigList();
      if (res.Success) {
        let resData = res.Data.filter((data) => data.Key == 'WebOrderMode');
        this.myVariable = resData[0].Value == 1 ? true : false;
      }
    },
    handleClick() {},
    showOrder() {
      this.$emit('showOrder', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tabs {
  background: #fff;
  padding: 20px 20px 0 20px;
  border-radius: 4px;
  height: 100vh;
}
// 深色
.my-elemente {
  .el-tabs {
    background: #cee8cf;
    padding: 20px 20px 0 20px;
    border-radius: 4px;
    height: 100vh;
  }
  .black-box {
    background: #cee8cf;
  }
  // 输入框样式
  ::v-deep .el-input__inner {
    background-color: #e4f7e5 !important;
    border-color: #a1cfa3;
    color: #545d5d;
  }
  ::v-deep .el-form-item__label {
    color: #545d5d;
  }
  ::v-deep .el-input .is-disabled .el-input__inner {
    border-color: #a1cfa3 !important;
  }
  ::v-deep .el-textarea .el-textarea__inner {
    background-color: #e4f7e5 !important;
    border-color: #a1cfa3;
    color: #4c5455;
  }
  ::v-deep .el-range-input {
    background-color: #e4f7e5 !important;
    border-color: #a1cfa3;
    color: #4c5455;
  }
  ::v-deep .el-tabs__item {
    color: #4c5455;
  }
  ::v-deep .el-table th.el-table__cell {
    background-color: #cee8cf !important;
    color: #4c5455;
  }
  ::v-deep .el-table {
    background: #cee8cf !important;
  }

  /*---表格边框---*/
  ::v-deep .el-table {
    border-color: #a1cfa3 !important;
  }
  ::v-deep .el-table td,
  .el-table th.is-leaf,
  .el-table--border,
  .el-table--group {
    border-color: #a1cfa3 !important;
  }
  ::v-deep .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #a1cfa3 !important;
  }
  ::v-deep .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #a1cfa3 !important;
  }
  ::v-deep .el-table--border .el-table__cell,
  .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #a1cfa3 !important;
  }
  ::v-deep .el-table .el-table__row:nth-child(odd) {
    background: #e4f7e5 !important;
    background-color: #e4f7e5 !important;
    color: #596062;
  }
  ::v-deep .el-table .el-table__row:nth-child(even) {
    background: #cee8cf !important;
    background-color: #cee8cf !important;
    color: #596062;
  }
}
</style>
