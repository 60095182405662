/*
 * @Description: 入户安检模板查看
 * @Author: wangjieqiong
 * @Date: 2023-08-25 11:04:21
 * @LastEditors: lushunjie
 * @LastEditTime: 2024-11-14 22:09:37
 * @FilePath: \src\mixin\householdInspection.js
 */

export default {
  data() {
    return {
      houseDialogVisible: false,
      detailChecked: '', //
      detailCheckedcontent: '',
      detailCheckedTemplate: '',
    };
  },
  components: {
    TempDynamic: () => import(/* webpackChunkName: "houseHoleInspection" */ '@/compPages/XkInhouse/Temp-Dynamic'),
    TempDynamicOld: () => import(/* webpackChunkName: "houseHoleInspection" */ '@/compPages/XkInhouse/Temp-Dynamic-Old'),
    BottleTempDynamic: () => import(/* webpackChunkName: "houseHoleInspection" */ '@/compPages/XkInhouse/Bottle-Dynamic'),
    BottleTempDynamic2: () => import(/* webpackChunkName: "houseHoleInspection" */ '@/compPages/XkInhouse/Bottle-Dynamic2'),
  },
  methods: {
    //查看入户安检模板详情
    getHouseInspectionDetail(row) {
      if (row.TemplateId) {
        // 新的动态模块
        this.detailCheckedTemplate = '';
        this.detailCheckedcontent = 'Dynamic';
        this.detailChecked = row;
        this.houseDialogVisible = true;
      } else {
        // 旧的JSON模板
        this.detailCheckedTemplate = '';
        this.detailCheckedcontent = 'DynamicOld';
        this.detailChecked = row;
        this.houseDialogVisible = true;
      }
    },
  },
};
