<!-- 下单管理 -->
<template>
  <div id="placeAnOrderAdd" class="flex-col">
    <el-form :inline="true" class="top-form-seach user-info-box">
      <el-form-item label="来电号码">
        <el-input maxLength="100" v-model="seachPhone" placeholder="来电号码" clearable></el-input>
      </el-form-item>
      <el-form-item align="right">
        <el-button type="primary" size="mini" round icon="el-icon-search" @click="getCustomer('Manual')">查询客户</el-button>
        <el-button type="danger" v-if="seachPhone" size="mini" round icon="el-icon-remove-outline" @click="changeBlack">加入黑名单</el-button>
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="add-form-box flex1 add-form-style" label-width="120px">
      <div class="form-title">
        <span>客户信息</span>
        <span v-if="cylinderRentOverdue" class="form-notice"><span class="notice-arrow"></span>{{ cylinderRentOverdue }}</span>
      </div>
      
      <GasUserForm class="user-info-box" :propsData="upData" :showAddress="'show'" :type="type" ref="gasUserForm"></GasUserForm>

      <el-form-item style="margin-bottom:10px">
        <el-col :span="12">
          <el-form-item class="user-info-box" label="当前用户积分">
            <el-input v-model="formData.Point" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="user-info-box" label="账户余额">
            <el-input v-model="formData.Deposit" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item align="right">
        <el-button type="primary" size="medium" round @click="addInfo">保存客户</el-button>
      </el-form-item>
    </el-form>
    <!-- 查询客户弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertCustome" :title="`查询客户`" :getIsfull="false" :titleVisible.sync="alertCustome">
      <template #content>
        <PlaceAnOrderAddCustomer :getCustomerType="getCustomerType" :phone="seachPhone" @getData="getChildrenData" @closeInfo="alertCustome = false"></PlaceAnOrderAddCustomer>
      </template>
    </XkMaxDialog>
  </div>
</template>

<script>
import PlaceAnOrderAddCustomer from '@/compPages/searchUser/index.vue'; //查询客户组件
import DivistionListCode from '@/components/DropDown/DivistionList';
import DropDownStationList from '@/components/DropDown/StationList';
import GasUserForm from '@/compPages/GasUForm.vue';
import _ from 'lodash';
import { postCustomerAdd, postCustomerUpdata, getCustomerget } from '@/api/customer';
// import{getConfigList} from '@/api/sysConfig'
import { getAddRemove } from '@/api/call';
import { getCustomerCylinderRentOverdue } from '@/api/cylinderPledge';
import { postCalllogsIn } from '@/api/call';
export default {
  name: 'placeAnOrderAdd',
  data() {
    return {
      upData: {
        Phone: '',
        Name: '',
        Number: '',
        Phone: '',
        GasAddress: '',
        AreaCode: '',
        StreetCode: '',
        IdentityNo: '',
        StationId: '',
        LicenseNumber: '',
        // seachPhone:'',
        myVariable: false,
      },
      seachPhone: '',
      formData: {},
      type: 'add',
      alertCustome: false, //查询客户弹窗
      getCustomerType: '', //手动查询，不关闭客户查
      props: {
        // 单选
        checkStrictly: true,
        // 懒加载
        lazy: true,
        lazyLoad(node, resolve) {},
      },
      cylinderRentOverdue:'',
    };
  },
  props: {
    getPhone: {
      type: String,
      default: '',
    },
  },
  components: {
    PlaceAnOrderAddCustomer,
    DivistionListCode,
    DropDownStationList,
    GasUserForm, //燃气新增表单
  },
  mounted() {
    // 监听 子组件gasUserForm 内的值变化
    this.$watch(() => {
      this.formData = this.$refs.gasUserForm.getFormData();
      
      
    });
  },
  created() {
    // 微信受理订单，用微信订单id查询客户信息,不在走后续用电话查询信息的流程
    let wxslCusId = window.localStorage.getItem('wxslCusId');
    let hrefUrl = window.location.href;
    if (wxslCusId && hrefUrl.indexOf('wxsl') != -1) {
      this.getWxslOrdId(wxslCusId);
      return;
    }
    // 改为多弹窗模式后 利用的路由传值
    if (this.$route.params.phone != '00000000000') {
      this.upData.Phone = this.$route.params.phone;
      this.seachPhone = this.$route.params.phone;
    } else {
      this.upData.Phone = '';
    }
    if (this.upData.Phone) {
      this.alertCustome = true;
    }
  },
  watch: {
    formData: {
      handler(n, o) {
        this.$nextTick(() => {
          let params = this.$refs.gasUserForm.getFormData();
          // if(params.seachPhone == this.seachPhone)return
          params.seachPhone = this.seachPhone;
          this.$store.dispatch('user/getOrderInfoData', params);
          this.getCylinderRentOverdue()
        });
      },
      deep: true,
    },
    upData: {
      handler(n, o) {
        this.upData.seachPhone = this.seachPhone;
        this.$store.dispatch('user/getOrderInfoData', this.upData);
      },
      deep: true,
    },
  },
  methods: {
    async geStyleCss() {
      let res = await getConfigList();
      if (res.Success) {
        let resData = res.Data.filter((data) => data.Key == 'WebOrderMode');
        this.myVariable = resData[0].Value == 1 ? true : false;
      }
    },
    // 加入黑名单addBlack
    changeBlack(row) {
      try {
        this.$confirm('确认将该号码加入黑名单？')
          .then(async () => {
            let params = {
              PhoneNo: this.seachPhone,
              CusId: null,
              AddType: true,
            };
            let res = await getAddRemove(params);
            if (res.code == 1) {
              this.$message({
                message: '加入黑名单成功',
                type: 'success',
              });
              this.seachPhone = '';
              this.resOrderPhone();
            }
          })
          .catch((_) => {});
      } catch (error) {}
    },
    // 利用微信受理id 查询
    async getWxslOrdId(id) {
      let res = await getCustomerget({ id: id });
      if (res?.code == 1) {
        this.$store.dispatch('user/getOrderInfoData', res.Data); //store 暂存数据
        this.getChildrenData(res.Data);
      }
    },
    // 重置
    resOrderPhone() {
      this.$store.dispatch('user/getOrderInfoData', {});
      for (let i in this.upData) {
        if (i != 'TypeId') {
          this.upData[i] = '';
        }
      }
    },
    // 查询
    query() {
      this.getPageList();
    },

    // 查询客户弹窗
    getCustomer() {
      this.getCustomerType = 'Manual'; //手动
      this.alertCustome = true;
    },
    getChildrenData(val) {
      let params = JSON.parse(JSON.stringify(val));
      // 判断是否是优洁能域名
      //  --> 优洁能下 判断是否本站点客户，如果不是清空站点信息重新选择
      let yjnUrl = window.location.hostname;
      if (yjnUrl == 'callntyjn.bi-tac.cn') {
        let config = window.sessionStorage.getItem('hostConfig');
        config = JSON.parse(config);
        if (config.Station.Id != val.StationId && config.Station.Classify == 2) {
          params.StationId = '';
          params.StationName = '';
        }
      }
      this.upData = params;
      this.type = 'edit';
      this.alertCustome = false;
    },

    // 保存数据
    addInfo: _.debounce(async function() {
      await this.$refs.gasUserForm.$refs['form'].validate();
      let params = this.$refs.gasUserForm.getFormData();
      console.log('修改的数据', params);
      let res, type;
      if (params.Id) {
        // 修改
        res = await postCustomerUpdata(params);
        type = 2;
      } else {
        // 新增
        res = await postCustomerAdd(params);
        type = 1;
      }
      if (res.Success) {
        //新用户信息暂存
        if (type == 2) {
          let resData = await getCustomerget({ id: params.Id });
          let setNewData = Object.assign({}, resData.Data);
          setNewData.seachPhone = this.seachPhone;
          this.$store.dispatch('user/getOrderInfoData', resData.Data);
        }
        if (type == 1) {
          let resData = await getCustomerget({ id: res.Data.Id });
          this.upData = resData.Data;
          // 清空重新赋值，监听变化
          this.$store.dispatch('user/getOrderInfoData', {});
          params.Id = res.Data.Id;
          this.$store.dispatch('user/getOrderInfoData', params);
          // 关联电话下单
          this.$store.dispatch('user/callbindCus',{id:res.Data.Id,phone:this.seachPhone})
        }
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      }
    }, 1000),
    async getCylinderRentOverdue(){
      this.cylinderRentOverdue =  ''
      let res = await getCustomerCylinderRentOverdue({
        customerId:this.formData.Id
      })
      if(res?.Success){
        this.cylinderRentOverdue =  res.Msg
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#placeAnOrderAdd {
  width: 100%;
  height: 100%;
}

.top-form-seach {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.add-form-box {
  background: #fff;
  margin-top: 14px;
  border-radius: 4px;
  padding-top: 10px;
}
.form-title {
  font-size: 16px;
  font-weight: bolder;
  line-height: 40px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  text-indent: 20px;
  color: #333;
}
// 深色
.my-elemente {
  .top-form-seach {
    background: #cee8cf;
    border-radius: 4px;
    padding: 20px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .add-form-box {
    background: #cee8cf;
    margin-top: 14px;
    border-radius: 4px;
    padding-top: 0;
  }
  .form-title {
    font-size: 16px;
    font-weight: bolder;
    line-height: 40px;
    border-bottom: 1px solid #93c695;
    margin-bottom: 10px;
    text-indent: 20px;
    color: #545d5d;
  }
  .user-info-box {
    ::v-deep .el-input__inner {
      background-color: #e4f7e5 !important;
      border-color: #a1cfa3;
      color: #545d5d;
    }
    ::v-deep .el-form-item__label {
      color: #505759;
    }
    ::v-deep .el-input.is-disabled .el-input__inner {
      border-color: #a1cfa3 !important;
    }
    ::v-deep .el-textarea .el-textarea__inner {
      background-color: #e4f7e5 !important;
      border-color: #a1cfa3 !important;
      color: #545d5d;
    }
  }
}
.flex-col {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #ddd;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #eee;
}
.form-notice{
  position: relative;
    border-radius: 4px;
    padding: 10px;
    z-index: 2000;
    font-size: 12px;
    line-height: 1.2;
    min-width: 10px;
    word-wrap: break-word;
    background: #303133;
    color: #fff;
    margin-left: 12px;
    content: " ";
    border-width: 5px;
    .notice-arrow,.notice-arrow:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      left: -5px;
      border-right-color: #303133;
      border-left-width: 0;
      
    }
    .notice-arrow{
      top: 14px;
    }
    .notice-arrow:after {
      bottom: -5px;
      // left: 1px;
      border-right-color: #303133;
      border-left-width: 0;
      content: " ";
      border-width: 5px;
    }
}
</style>
