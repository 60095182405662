/*
 * @Description: 楼层费用
 * @Author: MasterGehong
 * @Date: 2023-06-16 15:07:47
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-08-07 10:22:04
 * @FilePath: \src\api\floorDeliveryFee.js
 */
 
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/FeesConfig/`

// 楼层费用编辑
export const postFeesConfigEditFloor = (params, config = {}) => {
    return request.post(apiPrefix + 'EditFloor', params, config)
} 
// 获取楼层费用
export const getFeesConfigEditFloor = (params, config = {}) => {
    return request.get(apiPrefix + 'GetFloorFees', params, config)
} 