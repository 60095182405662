<!--左侧菜单-->
<template>
  <div v-if="item.Visible" class="layout-menu">
    <el-submenu v-if="item.Menus && item.Menus.length > 0 " :index="item.Id" >
      <template #title>  
        <XkSvgIcon :iconClass="item.Icon"/>
        <span>{{ item.Name }}</span>
       
      </template>
      <el-menu-item-group>
      <Menu v-for="child in item.Menus" :key="child.Id" :item="child" />
      </el-menu-item-group>
    </el-submenu>
    <template v-else-if=" item.Path !='/refuseOrder'">
        <el-menu-item :index="item.Path">
           <span :class="item.Icon?'':'iconactive'">{{ item.Name }}</span> 
          <span 
            :class="{'total-data':true,'acitve-back':$route.path ==item.Path}" 
            v-if="typeof(item.menusData) ==='number'">
            {{item.menusData}}
          </span>
        </el-menu-item>
    </template>
  </div>
</template>
<script>
import { getOrderCount } from '@/api/dashboard'  
import { mapGetters } from 'vuex'
export default {
  
   name:'Menu',
   props:{
       item:{
           type: Object,
           default:()=>{}
       },
    },
   data(){
       return{
          orderTotal:0,//订单总数
          weCharOrdersTotal:0,//微信订单受理
          weChatUsers:0,//微信审核
          refundReview:0,//退款审核
          speakTime:null,
       }
   },
   created(){  
     
   },
   mounted(){
   },
   computed: {
    ...mapGetters(["changeMenusData"]),
  },
  watch: {
    changeMenusData(newData, oldData) {
       // this.getPageList();
    }
  },
   methods:{
       // 获取表格数据
    async getPageList() {
      return
      clearInterval(this.speakTime)
      let res = await getOrderCount()
        if(res?.Success){   
              this.orderTotal =res.Data.Orders; 
              this.weCharOrdersTotal =res.Data.WeCharOrders;
              this.weChatUsers =res.Data.WeChatUsers;
              this.refundReview = res.Data.WeChatRefunds
              if(this.item.Path == '/wsAccount')return
              //如果有数据未处理，进行语音播报 
              if(this.orderTotal >0 || this.weCharOrdersTotal >0 || res.Data.WeChatUsers >0){
                let text=''
                if( this.orderTotal >0 || this.weCharOrdersTotal >0 ){  
                  text +="您有新的订单未处理请及时处理。"
                }
                if(res.Data.WeChatUsers >0){
                  text +=`您有新的微信审核未处理共${res.Data.WeChatUsers}条,请及时处理`
                }
                // alert(text)
                let utterance = new SpeechSynthesisUtterance(text); 
                window.speechSynthesis.speak(utterance);   
                this.$store.state.user.topCssBoolen=true; 
                setTimeout(() => {
                  this.$store.state.user.topCssBoolen=false; 
                }, 8000);
              } 
              let statTime = 3 * 60 *1000
              this.speakTime= setTimeout(() => {  
                this.getPageList();
              },statTime);
        }   
    }
   }
}
</script>

<style lang="scss" scoped>
.layout-menu{
    ::v-deep .el-menu-item-group__title {
      padding: 5px 0 5px 20px;
    }
    ::v-deep .el-submenu__title{
      height: 46px;
      line-height: 46px;
      color: var(--menu-item-color);
      font-weight: bold;
          &:hover {
              background-color: rgba(60,141,188,0.1);
          }
          .svg-icon{
              font-size: 16px;
              margin-right: 6px;
          }
      }
      .is-active{
         ::v-deep .el-submenu__title,::v-deep .el-submenu__title i{
              color: var(--menu-title-active-color);
              background-color: var(--menu-title-active-bg);
         }         
      }
    ::v-deep .el-menu-item{
          margin: 0 auto;
          height: 42px!important;
          line-height: 42px!important;
          color: var(--menu-item-color);
            &.is-active,&:hover {
              color:var(--menu-active-color);
              background: linear-gradient(to right,#2149F8,#38A6F9);
              border-left: 2px solid rgba(255,255,255,0.8);
              // span.iconactive{
              //   display: inline-block;
              //   width: 100%;
              //   position: relative;
              //   &::after{
              //     position: absolute;
              //     right: -20px;
              //     top: -1px;
              //     content: "";
              //     width: 3px;
              //     height: 42px;
              //     background: var(--main); //#096dd9
              //   }
              // }
            }
            
            .svg-icon{
              margin-right: 6px;
              font-size: 18px;
            }
    }
  }
  .total-data{
    display: block;
    float: right;
     margin-right: 20%; 
    height: 24px; 
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 12px; 
    color: #fff;
    padding: 0 4px;
    background: #F5222D; 
  }
  .acitve-back{
    background: rgba(255,255,255,0.9);
    color:#2D76F8 ;
  }
</style>