/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-09 10:35:56
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-05-09 14:00:51
 * @FilePath: \呼叫中心\src\utils\global.js
 */
/* 导出excel数据时，数据处理
 * @param {*} filterVal
 * @param {*} jsonData
 */
export function formatJson(filterVal, jsonData) {
  return jsonData.map((v) =>
    filterVal.map((j) => {
      return v[j];
    })
  );
}

/**
 * 时间格式化  formatDate(date,'yyyy-MM-dd')
 */
export function formatDate(time, fmt) {
  let date = new Date(time)
  let o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "H+": date.getHours(), // 小时
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}
/**
 * 日期控件默认当前月
 */
export function getmonthValue(){
  let date = new Date()
  let y = date.getFullYear()
  let m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  let d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  return y + '-' + m + '-' + d
}
/**
 * 日期控件默认上个月
 */
 export function getlastMonthValue(){
  let date = new Date()
  let y = date.getFullYear()
  let m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth()) : date.getMonth())
  let d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  return y + '-' + m + '-' + d
}
/**
 * 获取今天之前N天
 * 比如getLastDay(7) 获取7天前日期
 */
 export function getLastDay(day){
  var date = new Date();
  date.setDate(date.getDate() - day);
  let y = date.getFullYear();
  let m = ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1));
  let d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  return y + '-' + m + '-' + d
}

// 所有的正则规则
export const formReg = {
  //手机格式
  Phone:/^1[3|4|5|7|8][0-9]\d{8}$/,
  // 身份证
  IdentityNo: /^[1-9][0-9]{5}(19[1-9]{1}[0-9]{1}|194[2-9]{1}|2[0-9]{3})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
}