<!--
 * @Description: 
 * @Author: MasterGeHong
 * @Date: 2023-01-09 17:26:10
 * @LastEditTime: 2023-01-12 11:21:09
 * @LastEditors: MasterGeHong
-->
<template>
<XkContainer>  
    <input type="text" 
    placeholder="搜索用气地址"
    v-model="gasAddress" 
    id="mapSearch"  class="map-search"  name="mapSearch" />
    <span class="search-text" @click="addressToPoint(gasAddress)">搜索</span>
    <div id="mapBox"></div>
</XkContainer>
</template>

<script>

export default {
    props:{
        mapPointData:{
            type:Array,
            default:()=>[]
        },
        address:{
            type:String,
            default:''
        },
        cityCode:{
            type:String,
            default:''
        },
        gasAddressPoint:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{
            gasAddress:'',
            map:null,
            m1:null,
            m2:null,
            line:null,
            text:null, 
            marker:null,
            infoWindow:null,
            geocoder:null, 
            uId:'',
            oName:'',
            auto:null,
            placeSearch:null,
            gasPoint:true,
            addressPoint:[],
            
        }
    },
    computed:{},
    created(){
        this.gasAddress = this.address
        window.onclickEimt = this.onclickEimt;
    },
    mounted(){  
        this.initMap();
    },
    methods:{
        // 初始化地图
        initMap(){
            let that=this;
            this.map = new AMap.Map("mapBox", {
                resizeEnable: true,
                zoom: 20
            });   
            //输入提示 
            let autoOptions = {
                city:'',
                input: "mapSearch"
            };
            this.auto = new AMap.Autocomplete(autoOptions);
            this.placeSearch = new AMap.PlaceSearch({ 
               map: that.map
            });  //构造地点查询类 
            AMap.event.addListener(this.auto, "select", select);//注册监听，当选中某条记录时会触发
            function select(e) { 
                console.log(e.poi.name) 
                that.addressToPoint(e.poi.name)
            } 
            // 绘制用气地址坐标
            let point=this.gasPoint?this.gasAddressPoint:this.addressPoint
            this.drawGasPoint(point); 
            // 绘制送气工坐标 
            this.mapPointData.forEach(item=>{ 
                this.drawPoints(item);
                this.drawMapStyle();
                this.line.setMap(this.map);
                this.text.setMap(this.map);
                this.computeDis();
            }) 
            // 展示地图
            this.map.setFitView(); 
            // 点移动动态计算两点距离
            // this.m1.on('dragging', this.computeDis)
            // this.m2.on('dragging', this.computeDis)
        },
        // 计算两点距离
        computeDis(){
            var p1 = this.m1.getPosition();
            var p2 = this.m2.getPosition();
            var textPos = p1.divideBy(2).add(p2.divideBy(2));
            var distance = Math.round(p1.distance(p2));
            var path = [p1,p2];
            this.line.setPath(path);
            this.text.setText('两点相距'+distance+'米')
            this.text.setPosition(textPos)
        },
        // 绘制送气工坐标
        drawPoints(item){
            this.m2 = new AMap.Marker({
                map: this.map,
                draggable:false,
                icon:require('@/assets/images/icon-gasdis.png'), // 添加 Icon 图标 URL
                position:new AMap.LngLat(item.Longitude, item.Latitude), 
            });
           // 信息弹窗
            let infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});
            let marker = new AMap.Marker({
                position: [item.Longitude,item.Latitude],
                map:this.map,
                icon:require('@/assets/images/icon-gasdis.png'), // 添加 Icon 图标 URL
            });
            marker.xkName=item.Name;
            marker.xkId=item.Id;
            marker.content = `<div style="padding:10px 5px 10px 10px">
                 <p>姓名：<span style="color:#2D76F8" uId="${item.Id}">${item.Name}</span> </p>
                 <div class="pd-button" onclick="onclickEimt()">派单</button>
                </div>`;
            marker.on('click', markerClick); 
            var that=this;
            function markerClick(e) { 
                console.log(e)
                infoWindow.setContent(e.target.content);
                infoWindow.open(that.map, e.target.getPosition());
                that.uId = e.target.xkId;
                that.oName = e.target.xkName;
            } 
        },
         // 绘制两点距离样式
        drawMapStyle(){
           
            this.line = new AMap.Polyline({
                strokeColor:'#80d8ff',
                isOutline:true,
                outlineColor:'white'
            }); 
            this.text = new AMap.Text({
                text:'',
                style:{'background-color':'#29b6f6',
                        'border-color':'#e1f5fe',
                        'font-size':'12px'}
            });
        },
        // 绘制用气坐标点
        drawGasPoint(point){ 
            this.m1 = new AMap.Marker({
                map: this.map,
                draggable:false,//点移动
                icon:require('@/assets/images/icon-gasaddress.png'), // 添加 Icon 图标 URL
                position: new AMap.LngLat(point[0],point[1])
            });  
        }, 
        onclickEimt(val){ 
            let params ={
                id:this.uId,
                name:this.oName
            }
           this.$emit('getMapInfo',params) 
        },
        // 地址转经纬度
        addressToPoint(address){
                // 城市区域
                let geocoder = new AMap.Geocoder({
                    city: '', //默认：“全国”
                });  
                let that = this;
                geocoder.getLocation(address, function(status, result) { 
                    if (status === 'complete'&&result.geocodes.length) { 
                        let lnglat = result.geocodes[0].location; 
                        console.log('lnglat',lnglat)
                        that.addressPoint=[lnglat.lng,lnglat.lat]; 
                        that.gasPoint=false; 
                        that.initMap()

                    }else{
                        
                    }
                });
        },


    },

}
</script>

<style scoped > 
#mapBox{
    width: 100%;
    height: 70vh;
}
.map-search{
  width: 400px;
  height: 42px;
  border-radius: 5px;
  position: absolute;
  top: 15%;
  left: 20px;
  z-index: 999;
  outline: none;
  border: none;
  box-shadow:2px 2px 2px rgba(0, 0, 0, 0.1);
  text-indent: 10px;
}
.search-text{
    color: #999;
    position: absolute;
    z-index: 999;
    top:16%;
    left:380px;
    margin-top: 4px;
    cursor: pointer;
    user-select:none;
}
</style>