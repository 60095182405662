import { render, staticRenderFns } from "./complaintRegistration.vue?vue&type=template&id=ea99f9da&scoped=true&"
import script from "./complaintRegistration.vue?vue&type=script&lang=js&"
export * from "./complaintRegistration.vue?vue&type=script&lang=js&"
import style0 from "./complaintRegistration.vue?vue&type=style&index=0&id=ea99f9da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea99f9da",
  null
  
)

export default component.exports