<!--顶部-->
<template>
  <div class="nav-container">
    <div class="nav-user">
      <el-avatar size="medium">
        <i class="el-icon-user-solid"></i>
      </el-avatar>
      <label for="">账号：</label>
      <span class="value">{{ hostConfig.UserName }}</span>
      <label for="">座机号：</label>
      <span class="value">{{ hostConfig.SeatNo || '/' }}</span>
      <label for="">门店：</label>
      <span class="value">{{ hostConfig.Station.Name }}</span>
      <label for="">姓名：</label>
      <span class="value">{{ hostConfig.Name }}</span>

      <el-link v-if="webUrl == 'calllyyhq.bi-tac.cn'" href="http://lyyhq-enterprise.gas-tac.cn:81/" target="_blank" :underline="false">
        <el-button type="primary" size="mini" round plain icon="el-icon-s-platform">燃气系统</el-button>
      </el-link>
    </div>

    <div class="nav-right-menu">
      <div style="width:400px" v-if="planOrderPermission && planOrderPermission.indexOf('来电下单按钮') != -1">
        <span class="phone">
          <XkSvgIcon iconClass="iphone" />
          <input class="top-han-phone" v-model="handPhone" />
        </span>
        <el-button type="primary" size="small" round @click="handOrder">手动下单</el-button>
        <!-- <el-button type="primary" size="small" round plain>测试铃声</el-button> -->
      </div>
      <div @click="changeMenu">
        <el-badge :is-dot="topCssBoolen" class="item">
          <i v-if="!setColorStyle" class="el-icon-close-notification" style="font-size:27px;color:#828db5"></i>
          <XkSvgIcon v-else :class="{ 'top-tip-icon': topCssBoolen }" iconClass="notice" />
        </el-badge>
      </div>
      <div>
        <i class="el-icon-edit" @click="changePassWord"></i>
      </div>
      <div v-if="setAccountNumber != 1">
        <el-tooltip class="item" effect="dark" content="微信绑定" placement="bottom">
          <i class="el-icon-connection" style="font-size:20px" @click="changeWxDialog"></i>
        </el-tooltip>
      </div>
      <div title="退出登录">
        <XkSvgIcon class="logOut" @click="logout" iconClass="logout" />
      </div>
    </div>
    <XkMaxDialog title="二维码绑定" :isfullscreen="false" :width="'400px'" top="25vh" :dialogVisible.sync="dialogVisible" @closeFun="closeDialog">
      <template #content>
        <div v-show="unionid" class="un-div">
          <p class="tip-font">当前已绑定账号：{{ hostConfig.UserName }}</p>
          <el-button type="primary" class="un-btn" @click="unBind">解绑微信</el-button>
        </div>
        <BindQRcode v-show="!unionid"></BindQRcode>
      </template>
    </XkMaxDialog>
    <XkMaxDialog :dialogVisible.sync="alertAdd" title="修改密码" :width="'400px'" :getIsfull="false" :titleVisible.sync="alertAdd">
      <template #content>
        <XkPassWord :propsData="passWordForm" @closeInfo="alertAdd = false"></XkPassWord>
      </template>
    </XkMaxDialog>
  </div>
</template>

<script>
import { toLogout } from '@/router'; //登出
import { mapGetters, mapState } from 'vuex';
import BindQRcode from './BindQRcode';
import XkPassWord from '@/compPages/Password';
import { postWxBind, postWxUnBind } from '@/api/user.js';
import { postWxUserInfo } from '@/api/auth.js';
export default {
  name: 'Nav',
  computed: {
    ...mapGetters(['hostConfig', 'planOrderPermission']),
    ...mapState({
      topCssBoolen: (state) => state.user.topCssBoolen,
    }),
  },
  props: {
    orderShowphone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      handPhone: this.orderShowphone || '',
      alertAdd: false,
      passWordForm: {},
      webUrl: '',
      planPhoneRole: 0,
      dialogVisible: false,
      unionid: '',
      setAccountNumber: null,
      setColorStyle: true,
    };
  },
  created() {
    let locaitem = JSON.parse(window.localStorage.getItem('setAccountNumber'));
    this.unionid = window.localStorage.getItem('unionid');
    this.setAccountNumber = locaitem?.wxSupUserLogin == 1 ? 1 : 0;
    this.getWeChatBindingGO();
    this.webUrl = window.location.hostname;
  },
  mounted() {},
  components: {
    XkPassWord,
    BindQRcode,
  },
  methods: {
    changeMenu() {
      // this.setColorStyle = !this.setColorStyle;
      this.$emit('changeMenu');
    },
    async unBind() {
      try {
        let res = await postWxUnBind();
        if (res?.Success) {
          this.$message.success('解绑成功');

          window.localStorage.setItem('unionid', '');
          toLogout();
          // this.dialogVisible = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    //绑定二维码
    async getWeChatBindingGO() {
      if (this.$route.query.code && !this.hostConfig.WxUnionId) {
        let ores = await postWxUserInfo({ code: this.$route.query.code });
        if (ores?.Success) {
          let parms = {
            UnionId: ores.Data.unionid,
            OpenId: ores.Data.openid,
            NickName: ores.Data.nickname,
          };
          try {
            let res = await postWxBind(parms);

            if (res?.Success) {
              this.$message({
                message: '恭喜你，绑定成功',
                type: 'success',
              });
              let path = this.$route.path;
              this.$router.push(path);
              // window.localStorage.setItem('unionid', ores.Data.unionid);
            }
          } catch (error) {}
        }
      } else {
        return false;
      }
    },
    // 展示扫码绑定
    changeWxDialog() {
      this.dialogVisible = true;
    },
    // 改密
    async changePassWord() {
      this.alertAdd = true;
    },
    handOrder() {
      // 手动下单 清除微信受理缓存的 用户id  及订单id
      window.localStorage.setItem('wxslOrdId', '');
      this.$emit('handBtn', this.handPhone);
    },
    //登出
    logout() {
      this.$confirm('确认退出吗?', '提示')
        .then(() => {
          toLogout();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-bottom: 2px solid #e3e5eb;
    .nav-user {
      padding-left: 20px;
      display: flex;
      align-items: center;
      label {
        padding-left: 10px;
        color: var(--mainText);
        min-width: 55px;
      }
      span.value {
        color: var(--main);
        padding-right: 20px;
        max-width: 200px;
      }
    }
    ::v-deep .el-menu-item [class^='el-icon-'] {
      margin-right: 0;
    }
    .nav-right-menu {
      display: flex;
      align-items: center;
      margin-right: 0px;
      > div {
        padding: 0 30px;
        + div {
          border-left: 1px solid #eee;
        }
        .svg-icon {
          font-size: 30px;
          color: var(--subMainText);
        }
      }
      .phone {
        display: inline-block;
        border: 2px solid var(--main);
        border-radius: 8px;
        line-height: 28px;
        padding: 0px 15px;
        box-sizing: border-box;
        .svg-icon {
          font-size: 16px;
          vertical-align: middle;
        }
      }
      .nav-el-dropdown {
        cursor: pointer;
        @include nav;
        .el-icon-arrow-down {
          font-size: 12px;
        }
      }
      ::v-deep .el-button {
        margin-left: 20px;
      }
    }
    .logOut {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.top-han-phone {
  line-height: 24px;
  border: none;
  outline: none;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

// 顶部铃铛动画
@-webkit-keyframes zy {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes zy {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes zy {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}
@keyframes zy {
  10% {
    transform: rotate(30deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

.top-tip-icon {
  float: left;
  //   width: 60px;height: 60px;
  animation: zy 2.5s 0.15s linear infinite;
  -moz-animation: zy 2.5s 0.15s linear infinite; /* Firefox */
  -webkit-animation: zy 2.5s 0.15s linear infinite; /* Safari and Chrome */
  -o-animation: zy 2.5s 0.15s linear infinite; /* Opera */
}
.el-icon-edit {
  font-size: 24px;
  color: #888;
}
.tip-font {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}
.un-btn {
  margin: 0 auto;
}
.un-div {
  padding-bottom: 40px;
  text-align: center;
}
</style>
