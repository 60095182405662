/*
 * @version: 
 * @Author: Lukxwing
 * @Email: stormseeker@163.com
 * @Date: 2022-11-16 14:54:56
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-05-15 09:59:29
 */
import request from '@/utils/request'

// 退余气查询
export const getResidualReturnList = (params, config = {}) => {
  return request.get("/api/ResidualReturn/List", { params: params, ...config })
} 
// 添加退余气
export const getResidualReturnAdd = (params, config = {}) => {
  return request.post("/api/ResidualReturn/Add", params, config)
} 
let aixApi = '/api/ResidualReturn/'
 
// 修改退余气
export const getResidualReturnUpDate = (params, config = {}) => {
    return request.post(aixApi+'UpDate', params, config)
}; 
// 删除退余气
export const getResidualReturnDelete = (params, config = {}) => {
    return request.delete(aixApi+'Delete',  { params: params, ...config })
}; 
// 根据单号 删除退余气
export const postDeleteByOrderNumber= (params, config = {}) => {
  return request.post(aixApi+'DeleteByOrderNumber', params, config)
}; 
// 根据关联订单号查询退余气详情
export const getResByOrderNumber = (params, config = {}) => {
  return request.get(aixApi+'GetByOrderNumber',  { params: params, ...config })
}; 
// 根据id 查询退余气详情
export const getGetById = (params, config = {}) => {
  return request.get(aixApi+'GetById', { params: params, ...config })
}; 