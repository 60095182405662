/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-16 14:05:29
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-01-16 17:07:42
 * @FilePath: \src\api\repairInfo.js
 */
// 报修管理
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Repair/`

//  列表
export const getRepairInfoList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
}

// 新增
export const postRepairInfoAdd = (params, config = {}) => {
    return request.post(apiPrefix + 'Add', params, config)
}  
// 指派
export const putRepairInfoAppoint = (params, config = {}) => { 
    return request.put(apiPrefix + 'Assign', params, config)
}  
// 转派
export const postReAssign = (params, config = {}) => { 
    return request.post(apiPrefix + 'ReAssign', params, config)
}  
// 撤单
export const putRepairInfoQuash = (params, config = {}) => { 
    return request.put(apiPrefix + 'Quash', params, config)
}  
// 修改备注
export const putUpdateRepairRemark = (params, config = {}) => { 
    return request.put(apiPrefix + 'UpdateRepairRemark', params, config)
}  