<!-- 投诉登记页面 -->
<template>
  <XkContainer id="customerSubscription">
    <div class="flex-col" style="height:100vh">
      <el-form :inline="true" class="add-form-box add-form-style mt-10" label-width="80px">
        <el-col :span="8">
          <el-form-item label="投诉日期">
            <XkDateRangePick :startValue.sync="upData.BeginTime" :endValue.sync="upData.EndTime"> </XkDateRangePick>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="投诉号码">
            <XkDropDown url="getStationList" :val.sync="upData.StationId" lable="Name" value="Id" placeholder="投诉号码"></XkDropDown>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="内容">
            <XkDropDown url="getStationList" :val.sync="upData.StationId" lable="Name" value="Id" placeholder="内容"></XkDropDown>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="回复号码">
            <XkDropDown url="getStationList" :val.sync="upData.StationId" lable="Name" value="Id" placeholder="回复号码"></XkDropDown>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <XkDropDown url="getStationList" :val.sync="upData.StationId" lable="Name" value="Id" placeholder="状态"></XkDropDown>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item align="right">
            <el-button type="primary" round>查询</el-button>
          </el-form-item>
        </el-col>
      </el-form>
      <!-- 表格-->
      <div class="table-box">
        <el-table ref="mainTable" highlight-current-row border :data="tableData" height="100%" v-loading="tableLoading">
          <el-table-column width="50" label="序号" prop="rowNum" align="center"></el-table-column>
          <el-table-column label="投诉号码" prop="Number"></el-table-column>
          <el-table-column label="回复号码" prop="Name"></el-table-column>
          <el-table-column label="投诉内容"> </el-table-column>
          <el-table-column label="投诉时间" prop="Phone"></el-table-column>
          <el-table-column label="投诉状态" prop="IdentityNo"></el-table-column>
          <el-table-column label="接线员" prop="IdentityNo"></el-table-column>
          <el-table-column label="处理结果" prop="IdentityNo"></el-table-column>
          <el-table-column label="处理人" prop="IdentityNo"></el-table-column>
          <el-table-column label="操作" width="" class-name="table-opt" fixed="right">
            <template v-slot="scope">
              <XkConfirmButton type="danger" @click="handleDel(scope.row)" :loading="row._loading" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form :inline="true" class="add-form-box add-form-style mt-20">
        <el-col :span="8">
          <el-form-item label="证号">
            <XkDropDown url="getStationList" :val.sync="upData.StationId" lable="Name" value="Id" placeholder="客户名称"></XkDropDown>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="姓名">
            <el-input maxLength="100" placeholder="押瓶客户" v-model="upData.UserName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="投诉号码">
            <el-input maxLength="100" placeholder="押瓶客户" v-model="upData.UserName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="回复号码">
            <el-input maxLength="100" placeholder="押瓶客户" v-model="upData.UserName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="联系地址">
            <el-input maxLength="100" placeholder="押瓶客户" v-model="upData.UserName"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!-- 单据信息 -->
      <el-form class="mt-10" style="margin-right:10px">
        <el-form-item align="right">
          <el-button type="primary" round> 保存 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 商品新增弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertAdd" :title="`回收列表添加`" :getIsfull="false" :titleVisible.sync="alertAdd" width="400px">
      <template #content>
        <RecyclingBottleAdd :getHeight="'40vh'" @closeInfo="alertAdd = false"></RecyclingBottleAdd>
      </template>
    </XkMaxDialog>
  </XkContainer>
</template>
<script>
import RecyclingBottleAdd from './recyclingBottleAdd.vue';
import XkDateRangePick from '@/components/XkDateRangePick';
export default {
  name: 'customerSubscription',
  data() {
    return {
      tableLoading: false,
      alertAdd: false, //商品新增
      alertCustome: false, //查询客户弹窗
      upData: {},
      checked: true,
      tableData: [],
      fileUrl: [], //文件列表
    };
  },
  methods: {
    // 获取上传组件url
    getUploadList(obj) {
      this.fileUrl.push(obj);
      this.upData.Url = this.fileUrl.join('|');
    },
    // 删除某个文件
    removeItemUrl(index) {
      this.fileUrl.splice(index, 1);
    },
  },
  components: {
    RecyclingBottleAdd,
    XkDateRangePick, //日期组件
  },
};
</script>
<style lang="scss" scoped>
#customerSubscription {
  height: 100%;
}
.btm-form {
  margin-top: 30px;
}
.table-box {
  height: calc(100vh - 600px);
}
.tips {
  margin-left: 20px;
}
</style>
