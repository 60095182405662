<!--  客户押瓶-->
<template>
  <XkContainer>
    <div class="flex-col deposit-page" style="height:86vh;overflow-y: auto;">
      <div class="place-orderInfo-title">基础信息</div>
      <el-form v-loading="configFormVal" :inline="true" :model="upData" :rules="rules" ref="formName" class="add-form-box add-form-style mt-10">
        <el-col :span="8">
          <el-form-item label="押瓶客户" prop="UserName">
            <el-input maxLength="100" placeholder="请选择押瓶客户" :disabled="goinType == '1' ? false : true" v-model="upData.UserName">
              <template slot="suffix">
                <el-button v-if="goinType == '1'" type="primary" size="mini" @click="alertCustome = true">查询</el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="押瓶方式" v-if="type == 'add'" prop="PledgeType">
            <el-select class="gh-elect" v-model="upData.PledgeType" placeholder="请选择押瓶方式" @change="getConfigFun">
              <!-- <el-option label="全部"  :value="0">  </el-option> -->
              <el-option label="押瓶" :value="1"> </el-option>
              <el-option label="欠瓶" :value="3"> </el-option>
              <el-option label="押瓶+租瓶" :value="2"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="押瓶单号" :prop="configForm.BillNum == 1 ? 'BillNum' : 'noBillNum'">
            <el-input maxLength="100" v-model="upData.BillNum" placeholder="押瓶单号"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="本次站点" prop="StationId">
            <DropDownStationList roleStation="setRole" :val.sync="upData.StationId" placeholder="请选择站点"></DropDownStationList>
          </el-form-item>
        </el-col> -->
        <el-col :span="8" v-if="type == 'add'">
          <el-form-item label="本次站点" prop="StationId">
            <DropDownStationList roleStation="setRole" :val.sync="upData.StationId" placeholder="请选择站点"></DropDownStationList>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="type == 'add'">
          <el-form-item label="押瓶人员" prop="OperatorId">
            <DeliveryWorker
              ref="clearOpId"
              :urlParams="urlParams"
              @handleChange="getRolePs"
              :url="'getStationOperList'"
              :val="upData.OperatorId"
              placeholder="请选择送气工"
              lable="Name"
              value="Id"
            ></DeliveryWorker>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="type == 'add'">
          <el-form-item label="押瓶日期" prop="OperationTime">
            <el-date-picker style="width:100%" value-format="yyyy-MM-dd" v-model="upData.OperationTime" type="date" placeholder="押瓶日期" @change="operationTimeChange"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form>

      <!-- 表格区域 -->
      <el-form :inline="true">
        <el-col :span="24">
          <el-form-item><span class="place-orderInfo-title">押瓶信息</span></el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="alertAdd = true">添加</el-button>
            <span class="fc-blu tips" v-if="type == 'add'">多个气瓶条码以“/”隔开</span>
          </el-form-item>
        </el-col>
      </el-form>
      <div class="table-box" :style="{ height: `calc(86vh - ${height})` }">
        <el-table ref="mainTable" highlight-current-row border :data="tableData" height="100%" v-loading="tableLoading" show-summary :summary-method="getSummaries">
          <el-table-column label="序号" prop="RowIndex" align="center" width="55"></el-table-column>
          <el-table-column label="气瓶规格" prop="CylinderSpec"></el-table-column>
          <el-table-column label="数量" prop="CylinderCount" width="80">
            <template v-slot="scope">
              <el-input maxLength="100" v-model="scope.row.CylinderCount" @input="updateTotal(scope.row.CylinderCount)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="气瓶条码" prop="CylinderBarcodes">
            <template v-slot="scope">
              <el-input v-model="scope.row.CylinderBarcodes" maxLength="100"></el-input>
            </template>
          </el-table-column>
          <!--押金-->
          <el-table-column label="押金单价（元/个）" prop="Price" v-if="configForm.CylinderPledgePriceRule == '1'">
            <template v-slot="scope">
              <el-input-number style="width:100%" :controls="false" v-model="scope.row.Price" :precision="2" :step="0.1"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="押金单价（元/个）" prop="Price" v-else></el-table-column>
          <el-table-column label="押金总额（元）" prop="DepositTotalPrice">
            <template v-slot="scope">
              {{ getDepositTotal(scope.row) }}
            </template>
          </el-table-column>
          <!--租金-->
          <el-table-column label="租金收取方式" prop="RentChargeUnit">
            <template v-slot="scope"> {{ getRentChargeUnit(scope.row) }}</template>
          </el-table-column>
          <el-table-column label="租金单价（元/个）" prop="RentPrice">
            <template slot-scope="scope">
              {{ scope.row.RentChargeUnit ? scope.row.RentPrice : '' }}
            </template>
          </el-table-column>
          <el-table-column label="租瓶周期" prop="RentPeriod">
            <template slot-scope="scope" v-if="scope.row.RentChargeUnit">
              <el-input v-model="scope.row.RentPeriod" type="Number" @change="computedRentExpirationDate(scope.row, scope.$index)" :class="!scope.row.RentPeriod ? rentPeriodClass : ''"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="租瓶到期时间" prop="RentExpirationDate" width="170">
            <template slot-scope="scope" v-if="scope.row.RentChargeUnit">
              <el-date-picker v-model="scope.row.RentExpirationDate" type="date" value-format="yyyy-MM-dd" style="width: 145px;"></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="租金总额（元）" prop="RentTotalPrice">
            <template slot-scope="scope" v-if="scope.row.RentChargeUnit">
              {{ getRentTotal(scope.row) }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="钢瓶条码"  prop="IdentityNo" ></el-table-column>  -->
          <el-table-column label="操作" width="80" class-name="table-opt" fixed="right">
            <template v-slot="scope">
              <XkConfirmButton type="danger" @click="handleDel(scope.row)" :loading="row._loading" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 单据信息 -->
      <div class="place-orderInfo-title mt-10">单据信息</div>
      <el-form ref="djform" :inline="true" :model="upData" :rules="rules" class="add-form-box add-form-style mt-10">
        <el-col :span="8">
          <el-form-item label="押金总额">
            <el-input maxLength="100" placeholder="押金总额" v-model="upData.TotalPrice" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开户费">
            <el-input maxLength="100" placeholder="开户费" v-model="upData.AccountFee" @change="getAmount" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="折价费">
            <el-input maxLength="100" placeholder="折价费" v-model="upData.CovertingAmount" @change="getAmount" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预付租金" prop="Prepaidrent" :show-message="false" :rules="[
            { required: upData.PledgeType ==2 && configForm.BottleMortgagePrepaidrent=='1', message: '请输入预付租金', trigger: 'change' },
          ]">
            <el-input maxLength="100" placeholder="预付租金" v-model="upData.Prepaidrent" clearable oninput="value=value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/(\..{2}).*/g, '$1')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="应收金额" :prop="configForm.AmountReceivable == 1 ? 'AmountReceivable' : 'noAmountReceivable'">
            <el-input maxLength="100" placeholder="应收金额" v-model="upData.AmountReceivable" clearable :disabled="type == 'add' && configForm.IsAllowPawnBottleAmount == 1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付方式" prop="TradeType">
            <el-select class="gh-elect" v-model="upData.TradeType" clearable placeholder="支付方式">
              <el-option label="现金" :value="0"> </el-option>
              <el-option label="微信" :value="2"> </el-option>
              <el-option label="支付宝" :value="3"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form>
      <!-- 单据信息 -->
      <div class="place-orderInfo-title ">押金单据</div>
      <el-form :inline="true" :model="upData" ref="phoneform" :rules="rules" class="add-form-box add-form-style mt-10">
        <el-col :span="20">
          <!--upData.PledgeType == 1 ? 'AttachmentUrls' : 'noAttachmentUrls'  -->
          <el-form-item :prop="configForm.AttachmentUrls == 1 ? 'AttachmentUrls' : 'noAttachmentUrls'">
            <UploadImage ref="uploadImage" :showAddBtn="false" :limitSize="4" :limit="5" :urlList="infoImageUrl" @getUrlList="getUploadList" @removeItemUrl="removeItemUrl" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="">
            <el-button type="primary" :loading="btnLogin" round @click="newAddInfo"> 保存 </el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <!-- 商品新增弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertAdd" :title="`押瓶信息-添加`" :getIsfull="false" :titleVisible.sync="alertAdd" :width="'800px'">
      <template #content>
        <PlaceAnOrderSeachCustomer @addInfo="getPreInfo" @closeInfo="alertAdd = false"></PlaceAnOrderSeachCustomer>
      </template>
    </XkMaxDialog>
    <!-- 查询客户弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertCustome" :title="`查询客户`" :getIsfull="false" :titleVisible.sync="alertCustome">
      <template #content>
        <PlaceAnOrderAddCustomer :getCustomerType="getCustomerType" :UserName="upData.UserName" @getData="addInfo" @closeInfo="alertCustome = false"></PlaceAnOrderAddCustomer>
      </template>
    </XkMaxDialog>
  </XkContainer>
</template>
<script>
import PlaceAnOrderSeachCustomer from './placeAnOrderAddCustomer';
import PlaceAnOrderAddCustomer from '@/compPages/searchUser/index.vue'; //查询客户组件
import XkDateRangePick from '@/components/XkDateRangePick';
import UploadImage from '@/components/XkUpload/ApiUPImg';
import DeliveryWorker from '@/components/DropDown/DeliveryWorker';
import { postCylinderPledgeAdd, postCylinderPledgeUpdata } from '@/api/cylinderPledge';
import { mapGetters } from 'vuex';
import { getConfigAllList } from '@/api/sysConfig';
import DropDownStationList from '@/components/DropDown/StationList';
import dayjs from 'dayjs';

export default {
  name: 'userManageAdd',
  data() {
    var getAmountReceivable = (rule, value, callback) => {
      if (this.upData.AmountReceivable == '') {
        callback(new Error('请输入应收金额'));
      } else {
        callback();
      }
    };
    // 单据照片
    var getAttachmentUrls = (rule, value, callback) => {
      if (this.upData.AttachmentUrls?.length == 0) {
        callback(new Error('请上传单据'));
      } else {
        callback();
      }
    };
    var getBillNum = (rule, value, callback) => {
      if (this.upData.BillNum == '') {
        callback(new Error('请填写单号'));
      } else {
        callback();
      }
    };
    return {
      btnLogin: false, //加载下单按钮
      ruleData: '',
      tableLoading: false,
      alertAdd: false, //商品新增
      alertCustome: false, //查询客户弹窗
      upData: {
        TradeType: 0,
        TotalPrice: 0,
        UserName: '',
        CustomerId: '',
        AttachmentUrls: [], //押金单据
        BillNum: '',
        OperatorId: '',
        Operator: '',
        OperationTime: '',
        StationId: '',
        PledgeType: '',
        AmountReceivable: '',
        Prepaidrent: '',
      },
      rules: {
        UserName: [{ required: true, message: '请选择押瓶客户', trigger: 'blur' }],
        BillNum: [{ required: true, validator: getBillNum, trigger: 'blur' }],
        OperatorId: [{ required: true, message: '请选择押瓶人员', trigger: 'change' }],
        OperationTime: [{ required: true, message: '请选择押瓶日期', trigger: 'change' }],
        PledgeType: [{ required: true, message: '请选择押瓶方式', trigger: 'blur' }],
        AttachmentUrls: [{ required: true, validator: getAttachmentUrls, trigger: 'blur' }],
        AmountReceivable: [{ required: true, validator: getAmountReceivable, trigger: 'blur' }],
        StationId: [{ required: true, message: '请选择站点', trigger: 'blur' }],
      },
      checked: true,
      showOpe: false,
      tableData: [],
      infoImageUrl: [], //文件列表
      configForm: {},
      configFormVal: true,
      cusInfo: {},
      rentPeriodClass: '',
    };
  },
  props: {
    propsData: {
      type: Object,
      default: () => {},
    },
    getCustomerType: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'add',
    },
    height: {
      type: String,
      default: '550px',
    },
    goinType: {
      //goinType : 1 是压瓶页面做数据  2是下单页面做押瓶数据
      type: String,
      default: '1',
    },
  },
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
    urlParams: function() {
      return { stationId: this.upData.StationId };
    },
    //收取方式
    getRentChargeUnit: function() {
      return function(row) {
        const unit = row.RentChargeUnit;
        const unitMap = {
          1: '按天收取',
          2: '按月收取',
          3: '按年收取',
        };
        return unitMap[unit] || '';
      };
    },
    //获取押金总额
    getDepositTotal: function() {
      return function(row) {
        const price = row.Price || 0;
        const num = row.CylinderCount || 0;
        return price * num;
      };
    },
    //获取租金总额
    getRentTotal: function() {
      return function(row) {
        const price = row.RentChargeUnit ? row.RentPrice : 0; //单价
        const period = row.RentPeriod || 0; //周期
        const num = row.CylinderCount || 0; //数量
        return price * period * num;
      };
    },
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        this.upData.UserName = this.gettOrderInfoData.Name;
        this.upData.CustomerId = this.gettOrderInfoData.Id;
      },
      deep: true,
      immediate: true,
    },
    'upData.StationId': {
      handler(n, o) {
        if (this.type == 'add') this.$refs.clearOpId.values = '';
      },
      deep: true,
    },
  },
  created() {
    this.getConfigFun();
    this.upData.OperationTime = this.$ghGetTodayTime.timeStampToFormatedDateStr();
    if (this.type == 'edit') {
      this.getEditInfo();
    }
    if (this.getCustomerType == 'ordAdd' && Object.keys(this.propsData).length > 3) {
      this.tableData = this.propsData.Details;
      this.tableData?.forEach((item, index) => {
        item.RowIndex = index + 1;
        item.Price = item.DepositPrice;
        item.CylinderBarcodes = item.CylinderBarcodes?.length > 1 ? item.CylinderBarcodes?.join('/') : item.CylinderBarcodes[0];
      });
      this.tableData = JSON.parse(JSON.stringify(this.tableData));
      this.upData = JSON.parse(JSON.stringify(this.propsData));
      console.log('修改押瓶.', this.upData);
      this.infoImageUrl = this.upData.AttachmentUrls;
    }
  },

  methods: {
    async getConfigFun() {
      try {
        this.configFormVal = true;
        this.configForm = {};
        console.log(this.upData.PledgeType);
        let res = await getConfigAllList();

        res.Data.forEach((item) => {
          if (item.Key == 'FillRentalPeriod') {
            this.configForm.FillRentalPeriod = item.Value;
          }
          if (item.Key == 'IsAllowPawnBottleAmount') {
            this.configForm.IsAllowPawnBottleAmount = item.Value;
          }
          if (item.Key == 'BottleMortgagePrepaidrent') {
            this.configForm.BottleMortgagePrepaidrent = item.Value;
          }
          if (item.CategoryCode == 7 || item.CategoryCode == 8) {
            if (this.upData.PledgeType == 1) {
              if (item.Key == 'BottleMortgageNo') {
                // 押瓶单号
                this.configForm.BillNum = item.Value;
              }
              if (item.Key == 'BottleMortgageDocument') {
                // 押金单据
                this.configForm.AttachmentUrls = item.Value;
              }
              if (item.Key == 'BottleMortgageAmount') {
                // 应收金额
                this.configForm.AmountReceivable = item.Value;
              }
            }
            if (this.upData.PledgeType == 3) {
              if (item.Key == 'UnderBottleNo') {
                console.log(item.Value, 3);
                // 押瓶单号
                this.configForm.BillNum = item.Value;
              }
              if (item.Key == 'UnderBottleDocuments') {
                // 押金单据
                this.configForm.AttachmentUrls = item.Value;
              }
              if (item.Key == 'UnderBottleAmount') {
                // 应收金额
                this.configForm.AmountReceivable = item.Value;
              }
            }
            // 押瓶价格是否能改
            if (item.Key == 'CylinderPledgePriceRule') {
              this.configForm.CylinderPledgePriceRule = item.Value;
            }
          }
        });
        this.configFormVal = false;
        console.log('22', this.configForm);
      } catch (error) {
        console.log(error);
      }
      this.configFormVal = false;
    },
    getEditInfo() {
      this.upData = {
        Id: this.propsData.Id, //id
        CustomerId: this.propsData.CustomerId, //用户id
        UserName: this.propsData.CustomerName, //客户名称
        BillNum: this.propsData.BillNum, //单号
        // RoleId: , // 操作工ID
        OperationTime: this.propsData.OperationTime,
        PledgeType: this.propsData.PledgeType, //押瓶方式
        TotalPrice: this.propsData.TotalPrice, //押金总额
        AccountFee: this.propsData.AccountFee, //开户费
        CovertingAmount: this.propsData.CovertingAmount, //折价费
        AmountReceivable: this.propsData.AmountReceivable, //应收金额
        AttachmentUrls: this.propsData.AttachmentUrls,
        TradeType: this.propsData.TradeType,
        Prepaidrent: this.propsData.Prepaidrent,
      };
      this.infoImageUrl = this.propsData.AttachmentUrls; //单据

      this.tableData = this.propsData.PledgeDetail; //商品信息
      console.log('修改详情.', this.tableData, this.upData);
      this.tableData.forEach((item, index) => {
        item.RowIndex = index + 1;
        item.Price = item.DepositPrice;
        item.CylinderBarcodes = item.CylinderBarcodes?.length > 1 ? item.CylinderBarcodes?.join('/') : item.CylinderBarcodes[0];
      });
      this.tableData = JSON.parse(JSON.stringify(this.tableData));
      this.upData = JSON.parse(JSON.stringify(this.upData));
    },
    updateTotal(val) {
      //行内数量变动时候，重新赋值数量， 并且重新结算
      let getMoney = 0;
      this.tableData.forEach((item) => {
        let setMoney = parseFloat(item.CylinderCount || 0) * parseFloat(item.Price || 0);
        getMoney = getMoney + setMoney;
      });
      this.upData.TotalPrice = getMoney;
      this.upData = JSON.parse(JSON.stringify(this.upData));
      this.getAmount();
    },
    newAddInfo() {
      this.btnLogin = true; //加载下单按钮
      if (this.type == 'edit') {
        this.upData.DetailModel = this.tableData; // 修改商品详情
      } else if (this.type == 'add') {
        this.upData.Details = this.tableData; // 新增商品详情
      }

      const detailData = this.type == 'edit' ? this.upData.DetailModel : this.upData.Details;
      if (!detailData?.length) {
        this.$message.error('请添加押瓶信息!');
        this.btnLogin = false; //加载下单按钮
        return;
      }

      if (this.configForm.FillRentalPeriod == 1 && this.upData.PledgeType == 2) {
        for (let item of detailData) {
          if (!item.RentPeriod) {
            this.$message.error('存在未填写的租瓶周期');
            this.rentPeriodClass = 'isInvalid';
            this.btnLogin = false; //加载下单按钮
            return;
          }
        }
      }

      let setOff = false;
      this.$refs['djform'].validate((valid) => {
        //校验应收金额
        if (!valid) setOff = true;
      });
      this.$refs['phoneform'].validate((valid) => {
        if (!valid) setOff = true;
      });

      if (setOff) {
        this.btnLogin = false; //加载下单按钮
        return;
      }

      this.$refs['formName'].validate((valid) => {
        this.$debounce(async () => {
          if (valid) {
            if (this.goinType == '2') {
              let emitData = JSON.parse(JSON.stringify(this.upData));
              emitData.Details.forEach((item, index) => {
                item.DepositPrice = item.Price;
                item.CylinderBarcodes = item.CylinderBarcodes ? item.CylinderBarcodes?.split('/') : [];
              });
              this.$emit('getMortgageInfo', emitData);
              return;
            }
            let res;
            if (this.type == 'add') {
              let dataParams = JSON.parse(JSON.stringify(this.upData));
              dataParams.Details.forEach((item, index) => {
                item.CylinderBarcodes = item.CylinderBarcodes ? item.CylinderBarcodes?.split('/') : [];
                item.DepositPrice = item.Price;
              });
              if (dataParams.AmountReceivable == '') {
                dataParams.AmountReceivable = 0;
              }
              res = await postCylinderPledgeAdd(dataParams);
            } else {
              if (!this.upData.DetailModel.length) {
                this.$message.error('请添加押瓶信息。');
                return;
              }
              let dataParams = JSON.parse(JSON.stringify(this.upData));
              dataParams.DetailModel.forEach((item, index) => {
                item.CylinderBarcodes = item.CylinderBarcodes ? item.CylinderBarcodes?.split('/') : [];
                item.DepositPrice = item.Price;
              });
              if (dataParams.AmountReceivable == '') {
                dataParams.AmountReceivable = 0;
              }
              res = await postCylinderPledgeUpdata(dataParams);
            }
            if (res.Success) {
              this.btnLogin = false; //加载下单按钮
              this.$message({
                message: '保存成功',
                type: 'success',
              });
              this.$emit('closeInfo');
            } else {
              this.$message.error('保存失败');
            }
          } else {
            this.btnLogin = false; //加载下单按钮
          }
        });
      });
      this.btnLogin = false; //加载下单按钮
    },

    // 获取上传组件url
    getUploadList(url) {
      this.infoImageUrl.push(url);
      this.upData.AttachmentUrls = this.infoImageUrl;
    },
    // 删除某个图片
    removeItemUrl(index) {
      this.infoImageUrl.splice(index, 1);
      this.upData.AttachmentUrls = this.infoImageUrl;
    },

    addInfo(val) {
      this.cusInfo = val;
      this.upData.UserName = val.Name;
      this.upData.CustomerId = val.Id;
      if (val.Id) {
        this.alertCustome = false;
      }
    },
    // 配送工信息
    getRolePs(val) {
      console.log(val);
      this.upData.OperatorId = val.Id; //配送员Id
      this.upData.Operator = val.Name; //:配送员
    },
    // 删除
    handleDel(val) {
      this.tableData.forEach((item, index) => {
        if (item.CylinderSpec == val.CylinderSpec) {
          this.tableData.splice(index, 1);
        }
      });
      this.updateTotal();
    },
    getPreInfo(val) {
      if (val.length == 0) {
        this.alertAdd = false;
        return;
      }
      this.tableLoading = true;
      if (this.tableData.length == 0) {
        this.tableData = val;
      } else {
        // 过滤数据，如果有同id 数据不再添加
        this.tableData = this.tableData.concat(val);
        //定义一个空数组
        var arr5 = [];
        //遍历数组
        for (var i = 0; i < this.tableData.length; i++) {
          //定义一个数组用来临时存放合并后的数组元素
          var tmer = this.tableData[i];
          var flag = true;
          for (var j = 0; j < arr5.length; j++) {
            //判断
            if (tmer.CylinderSpec == arr5[j].CylinderSpec) {
              flag = false;
              break;
            }
          }
          //如果flag的值为true，则在arr5数组元素后添加元素
          if (flag) {
            arr5.push(tmer);
          }
        }
        this.tableData = arr5;
      }

      //  为数组重新排序
      this.upData.TotalPrice = 0;
      this.tableData.forEach((item, index) => {
        item.RowIndex = index + 1;
        item.CylinderCount = 1;
        this.upData.TotalPrice = this.upData.TotalPrice + item.Price;
      });
      // 深拷贝数组
      this.tableData = JSON.parse(JSON.stringify(this.tableData));
      this.alertAdd = false;
      this.tableLoading = false;
      this.getAmount();
    },

    //合计数据
    getSummaries(params) {
      let sums = new Array(12).fill('');
      sums[0] = '合计';
      let depositAll = 0,
        rentAll = 0;

      this.tableData.forEach((item) => {
        depositAll += this.getDepositTotal(item);
        rentAll += this.getRentTotal(item);
      });
      this.$nextTick(() => {
        this.$refs.mainTable.doLayout();
      });
      sums[5] = depositAll;
      sums[10] = rentAll;
      return sums;
    },

    operationTimeChange(e) {
      if (e) {
        for (let index in this.tableData) {
          const row = this.tableData[index];
          this.computedRentExpirationDate(row, index);
        }
      }
    },

    //计算租瓶到期时间
    computedRentExpirationDate(row, index) {
      if (!this.upData.OperationTime) return;
      const period = row.RentPeriod || 0; //周期
      const unit = row.RentChargeUnit;
      const unitMap = {
        1: 'day',
        2: 'month',
        3: 'year',
      };
      // const nowDay = dayjs().format('YYYY-MM-DD');
      const startDay = this.upData.OperationTime;
      const resultNextDay = dayjs(startDay).add(period, unitMap[unit]);
      const resultDay = dayjs(resultNextDay).subtract(1, 'day');
      this.$set(this.tableData[index], 'RentExpirationDate', dayjs(resultDay).format('YYYY-MM-DD'));
      this.getAmount();
    },

    //获取应收金额
    getAmount() {
      if (this.configForm.IsAllowPawnBottleAmount != 1 || this.type != 'add') return;
      let totalMoney = 0;
      this.tableData.forEach((item) => {
        const price = item.Price || 0;
        const num = item.CylinderCount || 0;
        const yj = price * num;

        const rentPrice = item.RentChargeUnit ? item.RentPrice : 0; //单价
        const period = item.RentPeriod || 0; //周期
        const zj = rentPrice * period * num;
        const itemTotal = yj + zj;
        totalMoney += itemTotal;
      });
      this.upData.AmountReceivable = totalMoney + Number(this.upData.AccountFee || 0) - Number(this.upData.CovertingAmount || 0);
    },
  },
  components: {
    PlaceAnOrderAddCustomer,
    PlaceAnOrderSeachCustomer, //查询客户
    XkDateRangePick, //日期组件
    UploadImage, //上传组件
    DeliveryWorker, //送气工 押瓶人员
    DropDownStationList,
  },
};
</script>
<style lang="scss" scoped>
.tips {
  margin-left: 20px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

::v-deep .deposit-page .el-form-item {
  margin-bottom: 12px;
}

::v-deep .isInvalid .el-input__inner {
  border-color: red;
}
</style>
