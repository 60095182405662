// 投诉管理
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/CustomerComplaint/`
// 详情
export const getComplaintDetail = (params, config = {}) => {
return request.get(apiPrefix + 'Detail', { params: params, ...config})
}
// 列表
export const getComplaintPageList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config})
    }

// 投诉回复
export const getComplaintReturn = (params, config = {}) => {
    return request.get(apiPrefix + 'Return', { params: params, ...config})
    }
// 新增
export const postComplaintAdd= (params, config = {}) => {
return request.post(apiPrefix + 'Add', params,config)
}
// 修改
export const putComplaintUpdate = (params, config = {}) => {
    return request.put(apiPrefix + 'Update', params,config)
    }