<!--
 * @Description: 
 * @Author: MasterGeHong
 * @Date: 2022-08-04 12:14:47
 * @LastEditTime: 2023-01-06 10:11:21
 * @LastEditors: MasterGeHong
-->
<template> 
    <XkContainer>
    <div class="dialog-add flex-col" :style="{height:getHeight}">
    <div class="flex1">
        <el-table ref="mainTable"  highlight-current-row  border :data="tableData" 
             tooltip-effect="dark"  @selection-change="handleSelectionChange"
        height="100%" v-loading="tableLoading">
        <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column label="序号" prop="rowNum" align="center"></el-table-column>
            <el-table-column label="规格"  prop="Number" ></el-table-column> 
        </el-table>
    </div> 
        <el-form class="mt-10">
            <el-form-item align="right"> 
                <el-button round> 取消</el-button>
                <el-button type="primary" round>确认</el-button>
            </el-form-item>
        </el-form>
    </div>
    </XkContainer>
</template>
<script>
export default {
    name:'customerSubscriptionAdd',
    data(){
        return{
          tableLoading:false,// 显示列表加载状态
          tableData:[{},{},{},{},]
        }
    },
    props:{
        getHeight:{
            type:String,
            default:'70vh'
        }
    },
    methods:{
        handleSelectionChange(value){
            console.log(value)

        },
        handleDel(){}
    }
}
</script>
<style lang="scss" scoped> 

</style>