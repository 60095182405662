/*
 * @Description: 
 * @Author: MasterGeHong
 * @Date: 2022-12-06 17:00:04
 * @LastEditTime: 2024-05-20 17:41:29
 * @LastEditors: MasterGehong
 */
import {titleText} from '@/api/auth'  
// 调用获取配置中的公司域名 
const getEnd = async()=>{ 
    let getDomainName= window.location.href.split('//')[1].split('.')[0]; 
    let res=await titleText();  
    let GETHTTPOBJ= res.Data.filter(e=>getDomainName == e.value);
    let params = GETHTTPOBJ?GETHTTPOBJ[0]:{}
   
//    params={
//         "value": "hy",
//         "label": "嘉盛华洋",
//         "print": true,
//         "logo": "hy.png",
//         "lastGasSupplier": true,
//         "ordJumpPrint":true,
//         "wxOrder":true,
//         "autoPrint":true,
//         "printType":"b",
//             "streetCode":'32',
//             "resident": true,
//             "zoneRequired": true
//     }
    params = JSON.stringify(params)
    // GETHTTPOBJ = JSON.stringify(params)
    window.localStorage.setItem('GETHTTPOBJ',params) 
}
getEnd();