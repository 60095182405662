<template>
  <XkContainer>
    <template #search>
      <el-form :inline="true" :model="queryData" style="width:83%;margin: 0 auto;">
        <el-form-item>
          <el-input maxLength="100" v-model="queryData.ProductName" placeholder="商品名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <XkDropDown url="getProductClassify" :val.sync="queryData.ClassifyId" placeholder="请选择商品分类" lable="Name" :value="'Id'"></XkDropDown>
        </el-form-item>

        <el-form-item class="search-btn">
          <el-button type="primary" round icon="el-icon-search" @click="getpageList">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <div class="dialog-add flex-col" :style="{ height: getHeight }">
      <div class="flex1">
        <el-table ref="mainTable" highlight-current-row border :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange" height="100%" v-loading="tableLoading">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="80" prop="RowIndex" align="center"></el-table-column>
          <el-table-column label="所属站点" prop="StationName"></el-table-column>
          <el-table-column label="商品名称" width="180" prop="Name"></el-table-column>
          <el-table-column label="商品分类" width="100" prop="ClassifyName"> </el-table-column>
          <el-table-column label="商品规格" width="100" prop="CylinderSpec"> </el-table-column>
          <el-table-column label="单价(元)" width="100" prop="Price" v-if="moneyType == '1'">
            <template v-slot="scope">
              <span v-if="scope.row.Special" style="color:red">{{ scope.row.Price }}</span>
              <span v-else>{{ scope.row.Price }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单价（元/kg)" width="100" prop="WeightPrice" v-else>
            <template v-slot="scope">
              <!-- Special 判断是否是特殊价格 -->
              <span v-if="scope.row.Special" style="color:red">{{ scope.row.WeightPrice }}</span>
              <span v-else>{{ scope.row.WeightPrice }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row type="flex" class="mt-10" justify="end">
        <el-col :span="6">
          <el-button round @click="close"> 取消</el-button>
          <el-button type="primary" round @click="addInfo">确认</el-button>
        </el-col>
      </el-row>
    </div>
  </XkContainer>
</template>
<script>
import { getProductCustomerList } from '@/api/product';
export default {
  name: 'customerSubscriptionAdd',
  data() {
    return {
      total: 0,
      tableLoading: false, // 显示列表加载状态
      tableData: [],
      dataForm: [],
      queryData: {},
    };
  },
  props: {
    getHeight: {
      type: String,
      default: '70vh',
    },
    cusId: {
      type: String,
      default: '',
    },
    moneyType: {
      type: String,
      default: '1',
    },
  },

  created() {
    if (this.cusId) this.queryData.CusId = this.cusId;
    this.getpageList();
  },
  methods: {
    async getpageList() {
      if (this.moneyType == '2') {
        this.queryData.purchType = 1;
      }
      let params = {};
      for (let i in this.queryData) {
        if (this.queryData[i]) {
          params[i] = this.queryData[i];
        }
      }
      let res = await getProductCustomerList(params);
      if (res.Success) {
        this.tableData = res.Data;
      }
    },
    addInfo() {
      this.$emit('addInfo', this.dataForm);
    },
    close() {
      this.$emit('closeInfo');
    },
    handleSelectionChange(value) {
      this.dataForm = value;
      return;
      if (this.dataForm.length == 0) {
        this.dataForm = value;
        return;
      }
      value.forEach((item, index) => {
        this.dataForm.forEach((element) => {
          if (item.Id != element.Id) {
            this.dataForm.push(item);
          }
        });
      });
    },
    handleDel() {},
  },
};
</script>
<style lang="scss" scoped></style>
