<!--
  ** 全局分页组件
-->
<template>
    <el-pagination
      background
      :current-page.sync="pageCurrent"
      :page-size.sync="pageSize"
      :page-sizes="pageSizes"
      :layout="layout"
      :total="Number(total)"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      total: {
        required: true,
        type: Number,
      },
      limit: {
        type: Number,
        default: 20
      },
      page: {
        type: Number,
        default: 1
      },
      pageSizes: {
        type: Array,
        default () {
          return [10, 20, 30, 50]
        }
      },
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next, jumper'
      },
      background: {
        type: Boolean,
        default: true
      }
    },
    computed:{
      pageCurrent:{
        get:function(){
          return this.page
        },
        set(val) {
          this.$emit('update:page', val)
        }
      },
      pageSize:{
        get:function(){
          return this.comPageSize
        },
        set(val) {
          this.$emit('update:limit', val)
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        //console.log(this.pageSize)
        this.comPageSize=val
        //localStorage.setItem("pageSize",val)//全局设置页数
        // this.$store.dispatch('pageSize/getPageSize',val)
        this.$emit('pagination', {
          pageCurrent: 1,
          comPageSize: val
        })
      },
      handleCurrentChange(val) {
        this.pageCurrent=val
        this.$emit('pagination', {
          pageCurrent: val,
          comPageSize: this.pageSize
        })
      }
    }
  }
</script>

<style scoped="scoped">
  .pagination-container {
    /* padding: 32px 16px; */
    text-align: right;
  }

  .pagination-container.hidden {
    display: none;
  }
</style>
