/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-28 14:56:22
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2024-10-23 10:01:00
 * @FilePath: \src\api\call.js
 */
// 站点增删改查
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/CallLogs/`

// 列表
export const getCallList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
 
// 录音文件
export const getRecordPath = (params, config = {}) => {
    return request.get(apiPrefix + 'RecordPath', { params: params, ...config })
}
 
// 移除 添加黑名单
export const getAddRemove= (params, config = {}) => {
    return request.post(apiPrefix + 'Blacklist/AddRemove',params, config)
}

// 黑名单查询
export const getBlacklist= (params, config = {}) => {
    return request.get(apiPrefix + 'Blacklist', { params: params, ...config })
}
// 拦截次数
export const getRejectList= (params, config = {}) => {
    return request.get(apiPrefix + 'RejectList', { params: params, ...config })
}
// 拦截次数
export const postCalllogsIn= (params, config = {}) => {
    return request.post(apiPrefix + 'CalllogsIn', params, config)
}
 